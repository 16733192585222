.common-tabbar_icon1 {
  width: 0.39rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.36rem;
  margin: auto;
  pointer-events: inherit;
  user-select: inherit;
  -webkit-user-select: inherit;
}
.common-tabbar_title {
  width: 6.04rem;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 0.48rem;
  line-height: 1;
  font-size: 0.48rem;
  font-weight: bold;
  color: #ec6602;
  left: 1.03rem;
  margin: auto;
}
.header-back.courseheaderback {
  z-index: 100;
}
.morelist {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  z-index: 102;
  top: 0;
  left: 0;
}
.morelist .morelist_box.csinfo.csinfoover {
  height: 3.62rem;
}
.morelist .morelist_box.csinfo {
  padding-bottom: 1.21rem;
}
.morelist .morelist_box.csinfo .morecentent_subtn.hover {
  background: #ec6602;
}
.morelist .morelist_box.csinfo .morelist_title {
  color: #ffffff;
  font-size: 0.39rem;
  font-weight: bold;
  padding-left: 0.48rem;
  line-height: 1.09rem;
}
.morelist .morelist_box.csinfo .morecentent_subtn {
  background: #cccccc;
  display: inline-block;
  position: absolute;
  bottom: 0.24154589rem;
  left: 0;
  right: 0;
  width: 2.42rem;
  height: 0.72rem;
  line-height: 0.72rem;
  text-align: center;
  color: #ffffff;
  border-radius: 1.20772947rem;
  margin: auto;
}
.morelist .morelist_box {
  width: 8.7rem;
  height: 11.23rem;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding-top: 1.09rem;
  overflow: hidden;
}
.morelist .morelist_box .morelist_cententcs {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
}
.morelist .morelist_box .morelist_cententcs .morecentent_title.groupinfo {
  text-align: center;
  padding-left: 0rem;
}
.morelist .morelist_box .morelist_cententcs .morecentent_title {
  font-size: 0.36rem;
  line-height: 0.56rem;
  padding-left: 0.58rem;
  padding-top: 0.3rem;
  padding-bottom: 0.18rem;
}
.morelist .morelist_box .morelist_cententcs .morelist_licslist {
  width: 7.25rem;
  padding-left: 0rem;
  padding-top: 0rem;
  margin: 0 auto;
  border-bottom: 0.01811594rem solid #bfbfbf;
  position: relative;
  min-height: 1.09rem;
  line-height: 1.09rem;
}
.morelist .morelist_box .morelist_cententcs .morelist_licslist span {
  width: 0.48rem;
  height: 0.48rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 0.03623188rem #cccccc solid;
  border-radius: 1.20772947rem;
}
.morelist .morelist_box .morelist_cententcs .morelist_licslist span i {
  display: none;
}
.morelist .morelist_box .morelist_cententcs .morelist_licslist span.hover {
  width: 0.48rem;
  height: 0.48rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 0.03623188rem #ec6602 solid;
  border-radius: 1.20772947rem;
}
.morelist .morelist_box .morelist_cententcs .morelist_licslist span.hover i {
  width: 0.24rem;
  height: 0.24rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border-radius: 1.20772947rem;
  background: #ec6602;
}
.morelist .morelist_box .morelist_centent {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.morelist .morelist_box .morelist_centent .morecentent_title {
  font-size: 0.36rem;
  line-height: 0.56rem;
  padding-left: 0.58rem;
  padding-top: 0.3rem;
  padding-bottom: 0.18rem;
}
.morelist .morelist_box .morelist_centent .morecentent_subtn {
  background: #ec6602;
  display: inline-block;
  position: absolute;
  bottom: 0.24154589rem;
  left: 0;
  right: 0;
  width: 2.42rem;
  height: 0.72rem;
  line-height: 0.72rem;
  text-align: center;
  color: #ffffff;
  border-radius: 1.20772947rem;
  margin: auto;
}
.morelist .morelist_box .morelist_centent .morelist_licslist {
  width: 7.25rem;
  padding-left: 0rem;
  padding-top: 0rem;
  margin: 0 auto;
  border-bottom: 0.01811594rem solid #bfbfbf;
  position: relative;
  min-height: 1.09rem;
  line-height: 1.09rem;
}
.morelist .morelist_box .morelist_centent .morelist_licslist span {
  width: 0.48rem;
  height: 0.48rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 0.03623188rem #cccccc solid;
  border-radius: 1.20772947rem;
}
.morelist .morelist_box .morelist_centent .morelist_licslist span i {
  display: none;
}
.morelist .morelist_box .morelist_centent .morelist_licslist span.hover {
  width: 0.48rem;
  height: 0.48rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 0.03623188rem #ec6602 solid;
  border-radius: 1.20772947rem;
}
.morelist .morelist_box .morelist_centent .morelist_licslist span.hover i {
  width: 0.24rem;
  height: 0.24rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border-radius: 1.20772947rem;
  background: #ec6602;
}
.morelist .morelist_box .morelist_centent .morelist_li {
  width: 7.25rem;
  padding-left: 1.69rem;
  padding-top: 0.36rem;
  margin: 0 auto;
  border-bottom: 0.01811594rem solid #bfbfbf;
  position: relative;
  min-height: 2.42rem;
}
.morelist .morelist_box .morelist_centent .morelist_li .morecontent_type {
  color: #ffffff;
  font-weight: bold;
  line-height: 1;
  font-size: 0.3rem;
  padding-bottom: 0.19rem;
}
.morelist .morelist_box .morelist_centent .morelist_li .morecontent_type span {
  background: #7e7e7e;
  display: inline-block;
  padding: 0.12077295rem 0.2173913rem;
  border-radius: 1.20772947rem;
  overflow: hidden;
}
.morelist .morelist_box .morelist_centent .morelist_li .morecontent_type span.gray {
  background: #7e7e7e;
}
.morelist .morelist_box .morelist_centent .morelist_li .morecontent_type span.nei {
  background: #ec6602;
}
.morelist .morelist_box .morelist_centent .morelist_li .morecontent_type span.blue {
  background: #009999;
}
.morelist .morelist_box .morelist_centent .morelist_li .morecontent_name:last-child {
  padding-bottom: 0.43rem;
}
.morelist .morelist_box .morelist_centent .morelist_li .morecontent_name {
  position: relative;
  padding-left: 1.81rem;
  font-size: 0.34rem;
  line-height: 0.56rem;
  color: #000000;
  padding-bottom: 0.12rem;
}
.morelist .morelist_box .morelist_centent .morelist_li .morecontent_name .morecontent_left {
  position: absolute;
  top: 0;
  left: 0;
  color: #7e7e7e;
}
.morelist .morelist_box .morelist_centent .morelist_li .morelist_li_headimg {
  width: 1.28rem;
  height: 1.28rem;
  position: absolute;
  top: 0.36rem;
  left: 0.06rem;
  border-radius: 3.62318841rem;
  overflow: hidden;
}
.morelist .morelist_box .morelist_centent .morelist_li .morelist_li_headimg img {
  width: 100%;
}
.morelist .morelist_box .morelist_top {
  position: absolute;
  width: 100%;
  height: 1.09rem;
  top: 0;
  left: 0;
  z-index: 2;
  background: #009999;
}
.morelist .morelist_box .morelist_top .morelist_close {
  width: 1.09rem;
  height: 1.09rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: url(../../static/images/closevideolayer.png) no-repeat center center / 0.36rem 0.36rem;
}
.courseselect_box {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
}
.courseselect_box .courseselect_bottombox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.courseselect_box .courseselect_firstbox.option06 .courseselect_firstlist {
  opacity: 0.5;
}
.courseselect_box .courseselect_firstbox {
  width: 3.38rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: #fff;
  padding-top: 1.21rem;
}
.courseselect_box .courseselect_firstbox .courseselect_firstlist {
  width: 100%;
  height: 100%;
  border-right: 0.01811594rem solid #e3e3e3;
  overflow: hidden;
}
.courseselect_box .courseselect_firstbox .courseselect_firstlist ul {
  padding-bottom: 1.81rem;
}
.courseselect_box .courseselect_firstbox .courseselect_firstlist ul li.active {
  border-bottom: 0.01811594rem solid #ec6602;
  color: #ec6602;
}
.courseselect_box .courseselect_firstbox .courseselect_firstlist ul li {
  margin: 0 0.36231884rem;
  border-bottom: 0.01811594rem solid #e0e0e0;
  line-height: 1;
  line-height: 0.51rem;
  font-size: 0.34rem;
  padding: 0.28985507rem 0rem;
}
.courseselect_box .courseselect_secstbox {
  width: 3.38rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  left: 3.02rem;
  z-index: 2;
  background: #fff;
  padding-top: 1.21rem;
}
.courseselect_box .courseselect_secstbox .courseselect_seclist {
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0.48309179rem 0.48309179rem 0.48309179rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.courseselect_box .courseselect_secstbox .courseselect_seclist ul {
  padding-bottom: 1.81rem;
}
.courseselect_box .courseselect_secstbox .courseselect_seclist ul li.active {
  border-bottom: 0.01811594rem solid #ec6602;
  color: #ec6602;
}
.courseselect_box .courseselect_secstbox .courseselect_seclist ul li {
  margin: 0 0.36231884rem;
  border-bottom: 0.01811594rem solid #e0e0e0;
  line-height: 1;
  line-height: 0.51rem;
  font-size: 0.34rem;
  padding: 0.28985507rem 0rem;
}
