.none {
  display: none;
}
.home {
  padding-bottom: 1.21rem;
}
#firstooter .singlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 222;
  display: none;
}
#firstooter .singlayer .singlayer_textbox {
  background: #ffffff;
  min-height: 1.20772947rem;
}
#firstooter .singlayer .singlayer_textbox .signlayer_title {
  font-size: 0.43rem;
  color: #ec6602;
  line-height: 1;
  text-align: center;
  padding: 0.74879227rem 0;
}
#firstooter .singlayer .singlayer_textbox .signlayer_list {
  padding-left: 1.06rem;
}
#firstooter .singlayer .singlayer_textbox .signlayer_list li::after {
  content: ' ';
  width: 0.34rem;
  height: 0.34rem;
  background: url(../../static/images/mall/mall_layer.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  left: -0.54rem;
}
#firstooter .singlayer .singlayer_textbox .signlayer_list li {
  width: 100%;
  position: relative;
  margin-bottom: 0.54rem;
}
#firstooter .singlayer .singlayer_textbox .signlayer_list li .signlayer_list_nums {
  text-align: right;
  width: 1.21rem;
  height: 0.48rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.53rem;
  font-weight: bold;
  font-size: 0.43rem;
  color: #ec6602;
}
#firstooter .singlayer .singlayer_textbox .signlayer_list li .signlayer_list_title {
  font-size: 0.39rem;
  color: #000000;
  line-height: 1;
}
#firstooter .singlayer .singlayer_textbox .signlayer_list li .signlayer_list_text {
  padding-top: 0.19rem;
  font-size: 0.29rem;
  color: #686868;
  line-height: 1;
}
#firstooter {
  width: 100%;
  height: 1.21rem;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 33;
  -webkit-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.05);
}
#firstooter ul {
  width: 100%;
  height: 100%;
}
#firstooter li.three {
  width: 33%;
}
#firstooter li {
  width: 25%;
  height: 100%;
}
#firstooter li a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
#firstooter .footer-contain {
  font-size: 0.24rem;
  color: #8c8c8c;
}
#firstooter .footer-icon {
  display: block;
  width: 100%;
  height: 0.48rem;
  margin-bottom: 0.12rem;
  margin-top: 0.12rem;
}
#firstooter .footer-text {
  text-align: center;
}
#firstooter .footer-text.active {
  color: #fe5b00;
}
#firstooter .footeradd_1 .footer-icon {
  background: url('../../static/images/homeplay/footer_icon_add1.png') no-repeat center center / auto 100%;
}
#firstooter .footeradd_1 .footer-icon.active {
  background: url('../../static/images/homeplay/footer_icon_add1_active.png') no-repeat center center / auto 100%;
}
#firstooter .footeradd_2 .footer-icon {
  background: url('../../static/images/homeplay/footer_icon_add2.png') no-repeat center center / auto 100%;
}
#firstooter .footeradd_2 .footer-icon.active {
  background: url('../../static/images/homeplay/footer_icon_add2_active.png') no-repeat center center / auto 100%;
}
#firstooter .footeradd_3 .footer-icon {
  background: url('../../static/images/homeplay/footer_icon_add3.png') no-repeat center center / auto 100%;
}
#firstooter .footeradd_3 .footer-icon.active {
  background: url('../../static/images/homeplay/footer_icon_add3_active.png') no-repeat center center / auto 100%;
}
#firstooter .footeradd_4 .footer-icon {
  background: url('../../static/images/homeplay/footer_icon_add4.png') no-repeat center center / auto 100%;
}
#firstooter .footeradd_4 .footer-icon.active {
  background: url('../../static/images/homeplay/footer_icon_add4_active.png') no-repeat center center / auto 100%;
}
#firstooter::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e3e3e3;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
