.shopmallscrollbox_detail {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}
.shopmallscrollbox_detail .shopmall_detail_btnbuynow {
  background: #fe5b00;
  color: #ffffff;
  padding: 0.24154589rem 0.72463768rem;
  border-radius: 1.20772947rem;
  position: fixed;
  right: 0;
  font-size: 0.39rem;
  right: 0.36rem;
  bottom: 1.69rem;
  z-index: 98;
}
.shopmallscrollbox_detail .shopmallscroll {
  padding-top: 1.21rem;
  padding-bottom: 1.45rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_banner {
  width: 100%;
  height: 3.86rem;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_banner img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_descbox::after {
  content: ' ';
  width: 0.41rem;
  height: 0.27rem;
  background: url(../../static/images/shopmall/shopmall_desc_arrow.png) no-repeat top left / 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  left: 0.46rem;
  bottom: -0.26rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_descbox {
  padding: 0 0.50724638rem;
  background: #f1f1f1;
  border-bottom: 0.01811594rem solid #dedede;
  position: relative;
  padding-bottom: 0.36rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_descbox .shopmall_detail_desc_title {
  font-size: 0.36rem;
  color: #0e9e9e;
  line-height: 1;
  padding-top: 0.36rem;
  padding-bottom: 0.27rem;
  position: relative;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_descbox .shopmall_detail_desc_title .shopmall_detail_desc_title_masthe {
  font-size: 0.24rem;
  border: 0.01811594rem solid #0e9e9e;
  padding: 0.07246377rem 0.19323671rem;
  background-color: #e3ecec;
  border-radius: 1.20772947rem;
  margin-left: 0.36rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_descbox .shopmall_detail_desc_title .shopmall_detail_desc_title_love {
  padding-left: 0.54rem;
  background: url(../../static/images/shopmall/shopmall_love.png) no-repeat top left / 0.36rem auto;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  top: 0.36rem;
  line-height: 0.41rem;
  right: -0.1rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_descbox .shopmall_detail_desc_title .shopmall_detail_desc_title_love.active {
  padding-left: 0.54rem;
  background: url(../../static/images/shopmall/shopmall_love_active.png) no-repeat top left / 0.36rem auto;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  top: 0.36rem;
  line-height: 0.41rem;
  right: -0.1rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_descbox .shopmall_detail_desc_text {
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.56rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox {
  line-height: 1;
  font-size: 0.34rem;
  padding: 0.36231884rem 0;
  color: #ec6602;
  border-bottom: 0.01811594rem solid #dedede;
  border-top: 0.01811594rem solid #dedede;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 0.12077295rem rgba(0, 0, 0, 0.05);
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox .shopmall_detail_btnlist {
  position: absolute;
  right: 0;
  right: 0.12rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox .shopmall_detail_btnlist span {
  float: right;
  margin-right: 0.24rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox .shopmall_detail_btnlist span.shopmall_detail_btntext {
  line-height: 0.63rem;
  margin-right: 0.36rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox .shopmall_detail_btnlist span.shopmall_detail_btnbuy {
  background: #ec6602;
  color: #ffffff;
  padding: 0.14492754rem 0.31400966rem;
  border-radius: 1.20772947rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox .shopmall_detail_btnlist span.shopmall_detail_isbuystate {
  border: 0.01811594rem solid #ec6602;
  color: #ec6602;
  padding: 0.12681159rem 0.30193237rem;
  border-radius: 1.20772947rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox span.shopmall_detail_span {
  position: relative;
  line-height: 1;
  top: 0;
  float: left;
  line-height: 0.56rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox .shopmall_detail_titleicon {
  width: 0.56rem;
  height: 0.56rem;
  background: url(../../static/images/shopmall/detail_icon.png) no-repeat top left / 100% 100%;
  display: inline-block;
  margin-left: 0.36rem;
  margin-right: 0.1rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox .shopmall_detail_titleline {
  margin-left: 0.17rem;
  margin-right: 0.17rem;
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox .shopmall_detail_titlefir {
  max-width: 1.21rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* 限制在一个块元素显示的文本的行数 */
  -webkit-box-orient: vertical;
  /* 垂直排列 */
  word-break: break-all;
  /* 内容自动换行 */
}
.shopmallscrollbox_detail .shopmallscroll .shopmall_detail_titlebox .shopmall_detail_titlesec {
  max-width: 2.17rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* 限制在一个块元素显示的文本的行数 */
  -webkit-box-orient: vertical;
  /* 垂直排列 */
  word-break: break-all;
  /* 内容自动换行 */
}
