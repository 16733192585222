.eshop-card {
  padding: 0;
  background: none;
  background: #ffffff;
  padding-bottom: 0.36rem;
}
.eshop-card .eshopcard-box {
  margin-top: 0.17rem;
  border-top: 0.01811594rem solid #dbdbdb;
  background: #ffffff;
  padding-left: 0.39rem;
  padding-right: 0.39rem;
}
.eshop-card .eshopcard-box .nocontent {
  padding-bottom: 1.21rem;
  margin-top: 1.21rem;
}
.eshop-card .eshopcard-box .eshopcard-card {
  color: #333333;
  font-size: 0.34rem;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-typetitle {
  color: #333333;
  font-size: 0.34rem;
  line-height: 1;
  padding: 0.31400966rem 0;
  border-bottom: 0.01811594rem solid #e5e5e5;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-borderline {
  border-bottom: 0.01811594rem solid #e5e5e5;
  box-shadow: 0 0.02415459rem 0.07246377rem rgba(0, 0, 0, 0.2);
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-cardnub {
  border-left: 0.04830918rem solid #2db4af;
  color: #333333;
  font-size: 0.34rem;
  padding-left: 0.39rem;
  margin-top: 0.41rem;
  line-height: 1;
  position: relative;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-cardnub span {
  position: absolute;
  top: 0;
  right: 0;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-btnlist {
  color: #333333;
  font-size: 0.31rem;
  line-height: 1;
  position: relative;
  border-top: 0.01811594rem solid #e5e5e5;
  margin: 0 0.36231884rem;
  padding: 0.31400966rem 0;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-btnlist span.gray {
  background: #b9b9b9;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-btnlist span {
  color: #ffffff;
  width: 1.96rem;
  line-height: 1;
  padding: 0.18115942rem 0;
  display: inline-block;
  background: #f59a23;
  border-radius: 1.20772947rem;
  text-align: center;
  margin: 0 0.42270531rem;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-type {
  color: #333333;
  font-size: 0.34rem;
  padding-left: 0.43rem;
  margin-top: 0.29rem;
  line-height: 1;
  position: relative;
  padding-bottom: 0.34rem;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-cardstate {
  color: #333333;
  font-size: 0.34rem;
  padding-left: 0.43rem;
  margin-top: 0.34rem;
  line-height: 1;
  position: relative;
  font-weight: bold;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-cardstate .cardstate.red {
  color: #ff3722;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-cardstate .cardstate.green {
  color: #2db4af;
}
.eshop-card .eshopcard-box .eshopcard-card .eshopcard-cardstate .cardtime {
  position: absolute;
  top: 0;
  right: 0;
}
.eshop-card .eshopcard-top {
  background: #ffffff;
  width: 100%;
  padding: 0.60386473rem 0.60386473rem;
  padding-top: 0.43rem;
  padding-bottom: 0.33rem;
  border-bottom: 0.01811594rem solid #dbdbdb;
}
.eshop-card .eshopcard-top .eshopcard-iconlist {
  overflow: hidden;
  text-align: left;
  padding-bottom: 0.41rem;
  border-bottom: 0.01811594rem solid #e5e5e5;
}
.eshop-card .eshopcard-top .eshopcard-iconlist .eshopcard-icon {
  display: inline-block;
  background: #2db4af;
  line-height: 1;
  color: #ffffff;
  padding: 0.16908213rem 0.33816425rem;
  border-radius: 1.20772947rem;
  margin-right: 0.27rem;
}
.eshop-card .eshopcard-top .cardtypelist {
  text-align: center;
  padding-top: 0.27rem;
}
.eshop-card .eshopcard-top .cardtypelist span {
  padding-right: 0.24rem;
}
.eshop-card .eshopcard-top .cardtypelist select {
  border: 0.01811594rem #cccccc solid;
}
