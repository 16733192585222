.auto-header {
  width: 100%;
  height: 1.21rem;
  z-index: 22;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  -webkit-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.05);
}
.auto-header .auto-logo {
  width: 2.17rem;
  width: 2.38rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  left: 0.36rem;
  margin: auto;
  pointer-events: inherit;
  user-select: inherit;
  -webkit-user-select: inherit;
}
.auto-header .auto-return {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0.41rem;
  right: 2.61rem;
  margin: auto;
  background: url('../../static/images/returnIcon.png') no-repeat center center / 100% 100%;
}
.auto-header .auto-return.active {
  background: url('../../static/images/returnIcon_active.png') no-repeat center center / 100% 100%;
}
.auto-header24 {
  z-index: 100;
}
.auto-header:after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e3e3e3;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0;
}
.gologintext {
  width: 1.57rem;
  display: block;
  height: 0.6rem;
  position: absolute;
  top: 0rem;
  font-size: 0.27rem;
  padding-top: 0.12rem;
  bottom: 0rem;
  right: -0.1rem;
  margin: auto;
}
.gologin {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0.39rem;
  right: 1.59rem;
  margin: auto;
  background: url('../../static/images/gologin.png') no-repeat center center / 100% 100%;
}
.shareicon {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 2.61rem;
  right: 3.62rem;
  margin: auto;
  background: url('../../static/images/shareicon.png') no-repeat center center / 100% 100%;
}
.shareicon.active {
  background: url('../../static/images/shareicon_active.png') no-repeat center center / 100% 100%;
}
.gohomeicon {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 3.62rem;
  right: 4.64rem;
  margin: auto;
  background: url('../../static/images/gohomeIcon.png') no-repeat center center / 100% 100%;
}
.gohomeicon.active {
  background: url('../../static/images/gohomeIcon_active.png') no-repeat center center / 100% 100%;
}
.gomymessage {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 2.61rem;
  margin: auto;
  background: url('../../static/images/messageicon.png') no-repeat center center / 100% 100%;
}
.gomymessage .messagenums {
  width: 0.27rem;
  width: auto;
  height: 0.19rem;
  height: auto;
  position: absolute;
  background: #ec6602;
  border-radius: 100%;
  border-radius: 0.27rem;
  display: inline-block;
  font-size: 0.19rem;
  color: #ffffff;
  top: 0;
  left: 0.47rem;
  top: 0.02rem;
  border: 0.01811594rem #ffffff solid;
  line-height: 0.01rem;
  line-height: 1;
  padding: 0.02415459rem 0.06038647rem;
  text-align: center;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
}
