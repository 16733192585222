.common-postli {
  min-height: 100px;
  padding-top: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0.31rem;
  border-bottom: 0.01811594rem #e3e3e3 solid;
}
.common-postli .postli_btnlist {
  margin-top: 0.19rem;
  margin-top: 0.36rem;
  position: relative;
  display: inline-block;
  width: 100%;
}
.common-postli .postli_btnlist .post_li_preview {
  color: #009797;
  position: absolute;
  line-height: 1;
  bottom: -0.1rem;
  border-radius: 1.93236715rem;
  right: 0;
  right: 3.26rem;
  padding: 0.12077295rem 0.28985507rem;
  border: 1px #009797 solid;
  z-index: 2;
}
.common-postli .postli_btnlist .post_li_tips {
  font-size: 0.34rem;
  line-height: 0.39rem;
  font-weight: bold;
  color: #ec6602;
}
.common-postli .postli_btnlist .post_li_change {
  color: #009797;
  position: absolute;
  line-height: 1;
  bottom: -0.1rem;
  border-radius: 1.93236715rem;
  right: 0;
  right: 1.69rem;
  padding: 0.12077295rem 0.28985507rem;
  border: 1px #009797 solid;
  z-index: 2;
}
.common-postli .postli_btnlist .post_li_del {
  color: #ec6602;
  line-height: 1;
  position: absolute;
  bottom: -0.1rem;
  border-radius: 1.93236715rem;
  right: 0;
  padding: 0.12077295rem 0.28985507rem;
  border: 1px #ec6602 solid;
  z-index: 2;
}
.common-postli .postli_btnlist span {
  float: left;
  position: relative;
  padding-left: 0.51rem;
  color: #929292;
  font-size: 0.34rem;
  line-height: 0.39rem;
  margin-right: 0.48rem;
}
.common-postli .postli_btnlist .post_li_love::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  bottom: 0.02rem;
  width: 0.37rem;
  height: 0.35rem;
  background: url(../../static/images/notice/loveicon.png) no-repeat top left / 100% 100%;
}
.common-postli .postli_btnlist .post_li_comment::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0.37rem;
  height: 0.35rem;
  background: url(../../static/images/notice/comment.png) no-repeat top left / 100% 100%;
}
.common-postli .postli_btnlist .post_li_playcount::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0.37rem;
  height: 0.37rem;
  background: url(../../static/images/playnumsicon.png) no-repeat top left / 100% 100%;
}
.common-postli .postli_contents.hasphoto div.iscontent {
  width: 6.04rem;
  width: 6.5rem;
  word-wrap: break-word;
  word-break: break-all;
}
.common-postli .postli_contents.hasphoto div.iscontent img {
  display: none;
}
.common-postli .postli_contents {
  position: relative;
  padding-top: 0.21rem;
}
.common-postli .postli_contents div.iscontent {
  font-size: 0.36rem;
  color: #1a1a1a;
  line-height: 0.48rem;
  word-wrap: break-word;
  word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.common-postli .postli_contents div.iscontent img {
  display: none;
}
.common-postli .postli_contents .postli_contents_img {
  width: 2.29rem;
  height: 1.59rem;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  bottom: 0.1rem;
}
.common-postli .postli_contents .postli_contents_img img {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
}
.common-postli .postli_theme {
  line-height: 0.43rem;
  font-size: 0.34rem;
  color: #ec6602;
  padding-top: 0.36rem;
}
.common-postli .postli_title.notitle {
  padding-top: 0.46rem;
}
.common-postli .postli_title.haveimg {
  padding-right: 2.42rem;
}
.common-postli .postli_title {
  font-size: 0.39rem;
  color: #1a1a1a;
  padding-top: 0.12rem;
  line-height: 0.51rem;
  font-weight: bold;
}
.common-postli .postli_title .isbesticon {
  width: 0.72rem;
  height: 0.35rem;
  background: url(../../static/images/good_iconnew.png) top left / 100% 100%;
  display: inline-block;
  position: relative;
  top: 0.04rem;
}
.common-postli .postli_userinfo {
  color: #929292;
  line-height: 1;
  font-size: 0.31rem;
  line-height: 0rem;
  margin-top: 0.25rem;
  overflow: hidden;
}
.common-postli .postli_userinfo .userinfo_headimg {
  width: 0.48rem;
  height: 0.48rem;
  display: inline-block;
  float: left;
  margin-right: 0.14rem;
  overflow: hidden;
  border-radius: 100px;
}
.common-postli .postli_userinfo .userinfo_nickname {
  display: inline-block;
  line-height: 0.46rem;
}
.common-postli .postli_userinfo .userinfo_office {
  font-size: 0.22rem;
  line-height: 0.29rem;
  margin-left: 0.16rem;
  color: #009c9a;
  background: #f0f9f9;
  border-radius: 1.20772947rem;
  padding: 0 0.12077295rem;
  border: 0.01811594rem solid #009c9a;
}
