.common-talklist {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.common-talklist .nohasnotice {
  padding-top: 3.62rem;
  color: #adadad;
  font-size: 0.36rem;
  text-align: center;
}
.common-talklist .talklist_layer {
  width: 100%;
  height: 95vh;
  background: #ffffff;
  position: absolute;
  top: 5vh;
  left: 0;
}
.common-talklist .talklist_layer .interactioncontent_bottomtalk {
  position: absolute;
  border-top: 0.01811594rem solid #e3e3e3;
  box-shadow: 0rem 0rem 0.03623188rem 0.07246377rem rgba(0, 0, 0, 0.03);
}
.common-talklist .talklist_layer .talklist_layer_scroll {
  width: 100%;
  height: 90%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 1.09rem;
}
.common-talklist .talklist_layer .talklist_layer_content {
  padding-bottom: 1.09rem;
  padding-bottom: 0.72rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li.active {
  padding-left: 1.43rem;
  padding-right: 0.4rem;
  margin-left: 0rem;
  margin-right: 0rem;
  padding-bottom: 0.35rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li.active .talklist_layer_content_li_nickname .talklist_layer_content_li_type {
  display: initial;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li.active .talklist_layer_content_li_dates {
  padding-top: 0.24rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li.active .talklist_layer_content_li_dates .talklist_layer_content_li_dates_goask {
  display: none;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li.active .talklist_layer_content_li_headimg {
  position: absolute;
  width: 0.77rem;
  height: 0.77rem;
  top: 0.36rem;
  left: 0.41rem;
  border-radius: 100%;
  overflow: hidden;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li {
  margin-left: 1.43rem;
  margin-right: 0.4rem;
  position: relative;
  padding-top: 0.43rem;
  padding-bottom: 0.51rem;
  border-bottom: 0.01811594rem solid #e3e3e3;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_dates {
  color: #929292;
  font-size: 0.29rem;
  line-height: 1;
  padding-top: 0.42rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_dates .talklist_layer_content_li_dates_goask {
  background: #f5f5f5;
  padding: 0.14492754rem 0.12077295rem;
  border-radius: 0.26570048rem;
  color: #000;
  margin-right: 0.29rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_dates .talklist_layer_content_li_dates_del {
  float: right;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_text {
  color: #1a1a1a;
  font-size: 0.36rem;
  line-height: 0.53rem;
  padding-top: 0.24rem;
  word-wrap: break-word;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_backuser {
  background: #f8f8f8;
  color: #707070;
  font-size: 0.36rem;
  line-height: 0.48rem;
  padding: 0.12077295rem 0.2173913rem;
  margin-top: 0.34rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_img {
  width: 2.17rem;
  height: auto;
  margin-top: 0.21rem;
  overflow: hidden;
  border-radius: 0.09661836rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_nickname {
  line-height: 1;
  font-size: 0.34rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_nickname .talklist_layer_content_li_type {
  margin-left: 0.18rem;
  background: #ffeee1;
  color: #ec6602;
  display: none;
  font-size: 0.24rem;
  padding: 0.04830918rem 0.09661836rem;
  border-radius: 0.04830918rem;
  position: relative;
  top: -0.02rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_nickname .talklist_layer_content_li_zan {
  padding-right: 0.48rem;
  position: relative;
  float: right;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_nickname .talklist_layer_content_li_zan span {
  position: relative;
  top: 0.05rem;
  font-size: 0.31rem;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_nickname .talklist_layer_content_li_zan.active {
  color: #ec6602;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_nickname .talklist_layer_content_li_zan.active::after {
  content: ' ';
  top: 0;
  right: 0;
  width: 0.35rem;
  height: 0.35rem;
  position: absolute;
  background: url(../../static/images/notice/zan_active.png) no-repeat top left / 100% 100%;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_nickname .talklist_layer_content_li_zan::after {
  content: ' ';
  top: 0;
  right: 0;
  width: 0.35rem;
  height: 0.35rem;
  position: absolute;
  background: url(../../static/images/notice/zan.png) no-repeat top left / 100% 100%;
}
.common-talklist .talklist_layer .talklist_layer_content .talklist_layer_content_li .talklist_layer_content_li_headimg {
  position: absolute;
  width: 0.77rem;
  height: 0.77rem;
  top: 0.36rem;
  left: -1.04rem;
  border-radius: 100%;
  overflow: hidden;
}
.common-talklist .talklist_layer .talklist_layer_title {
  width: 100%;
  height: 0.36rem;
  height: auto;
  line-height: 0.41rem;
  text-align: center;
  color: #1a1a1a;
  font-size: 0.41rem;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border-bottom: 0.01811594rem solid #e3e3e3;
}
.common-talklist .talklist_layer .talklist_layer_title .cloasethislayer {
  width: 0.37rem;
  height: 0.37rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.36rem;
  background: url(../../static/images/notice/closelayer_tips.png) no-repeat top left / 100% 100%;
}
