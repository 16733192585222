.common-usercback {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.common-usercback .common-usercbackcback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
}
.common-usercback .usercback_bottom_box.active {
  height: 100%;
}
.common-usercback .usercback_error {
  position: absolute;
  font-size: 0.36rem;
  color: #fa0000;
  bottom: 0;
  right: 0;
  bottom: 0.22rem;
  right: 0.77rem;
  line-height: 1;
}
.common-usercback .usercback_bottom_box {
  width: 100%;
  height: 2.51rem;
  height: auto;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: 0;
  border-top: 0.01811594rem #e3e3e3 solid;
  padding: 0.31400966rem 0;
  text-align: left;
  box-shadow: 0 -0.03623188rem 0.07246377rem rgba(0, 0, 0, 0.05);
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea.active {
  padding-bottom: 0.24rem;
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea {
  width: 8.09rem;
  height: 1.88rem;
  height: auto;
  height: 100%;
  border: 0.01811594rem #e7e7e7 solid;
  background: #f8f8f8;
  border-radius: 0.09661836rem;
  position: relative;
  left: 0.39rem;
  padding: 0.24rem;
  padding-bottom: 0.66rem;
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea textarea {
  width: 100%;
  height: 100%;
  height: 0.97rem;
  background: none;
  border-radius: none;
  border: 0;
  resize: none;
  color: #1a1a1a;
  line-height: 0.53rem;
  font-size: 0.39rem;
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea textarea.active {
  height: 5.19rem;
  height: 85%;
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea textarea::-webkit-input-placeholder {
  color: #cacaca;
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea .usercback_addpicbtn {
  width: 0.45rem;
  height: 0.45rem;
  width: 0.81rem;
  height: 0.81rem;
  z-index: 9;
  position: absolute;
  right: 0.18rem;
  bottom: 0.18rem;
  right: 0rem;
  bottom: 0rem;
  background: url(../../static/images/notice/addpic_icon.png) no-repeat center center / 0.4468599rem 0.4468599rem;
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea .imglist.active {
  position: absolute;
  bottom: 0.24rem;
  left: 0.24rem;
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea .imglist {
  width: 1.59rem;
  height: 1.59rem;
  position: relative;
  bottom: 0;
  left: 0;
  margin-top: 0.42rem;
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea .imglist img {
  border: 0.01811594rem #e6e6e6 solid;
  border-radius: 0.07246377rem;
  height: 100%;
}
.common-usercback .usercback_bottom_box .usercback_bottom_textarea .imglist .clearimg {
  width: 0.56rem;
  height: 0.57rem;
  right: -0.27rem;
  top: -0.19rem;
  position: absolute;
  background: url(../../static/images/notice/clearpic_btn.png) no-repeat top left / 100% 100%;
}
.common-usercback .usercback_bottom_box .changetextarea {
  width: 0.42rem;
  height: 0.41rem;
  position: absolute;
  right: 0.41rem;
  top: 0.43rem;
  background: url(../../static/images/notice/changetextarea.png) no-repeat top left / 100% 100%;
}
.common-usercback .usercback_bottom_box .changetextarea.active {
  width: 0.42rem;
  height: 0.41rem;
  position: absolute;
  right: 0.41rem;
  top: 0.43rem;
  background: url(../../static/images/notice/changetextarea_active.png) no-repeat top left / 100% 100%;
}
.common-usercback .usercback_bottom_box .subtext {
  color: #cacaca;
  position: absolute;
  line-height: 0.39rem;
  font-size: 0.39rem;
  right: 0.39rem;
  bottom: 0.66rem;
}
.common-usercback .usercback_bottom_box .subtext.active {
  color: #ec6602;
  position: absolute;
  line-height: 0.39rem;
  font-size: 0.39rem;
  right: 0.39rem;
  bottom: 0.66rem;
}
