.history-list {
  padding: 0 0.36231884rem;
}
.history-list li {
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
  padding-left: 3.38rem;
  min-height: 3.02rem;
  position: relative;
}
.history-list li a {
  padding-bottom: 0.72rem;
}
.history-list li::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e8e8e8;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0rem;
  left: 0rem;
}
.history-list .hotdown-img {
  display: block;
  width: 3.02rem;
  height: 1.98rem;
  position: absolute;
  top: 0.48rem;
  left: 0rem;
  overflow: hidden;
  background: #f9f9f9;
}
.history-list .hotdown-img img {
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}
.history-list .hotdown-img b {
  position: absolute;
  bottom: 0;
  right: 0.06rem;
  bottom: 0.06rem;
  font-style: normal;
  font-size: 0.24rem;
  background: #ec6602;
  line-height: 1;
  color: #ffffff;
  font-weight: inherit;
  border-radius: 0.48309179rem;
  padding: 0.06038647rem 0.12077295rem;
  text-align: center;
  max-width: 1.21rem;
  white-space: nowrap;
  overflow: hidden;
}
.history-list .hotdown-title {
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.53rem;
  padding-top: 0.12rem;
}
.history-list .history-name {
  display: block;
  font-size: 0.24rem;
  color: #686868;
  margin-top: 0.17rem;
}
.history-list .history-name span {
  color: #929292;
}
.history-list .history-time {
  position: absolute;
  bottom: 0.53rem;
  right: 0rem;
  color: #b7b7b7;
  font-size: 0.24rem;
  line-height: 0.36rem;
}
.history-list .history-play {
  position: absolute;
  bottom: 0.53rem;
  left: 3.38rem;
  color: #b7b7b7;
  font-size: 0.24rem;
  line-height: 0.36rem;
  padding-left: 0.36rem;
  display: none;
  background: url("../../static/images/hisplaynums.png") no-repeat left center / auto 0.26570048rem;
}
