.elasticframepayscore {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  left: 0rem;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
.elasticframepayscore .golivedetailbox {
  overflow: hidden;
  text-align: center;
}
.elasticframepayscore .golivedetailbox .golivedetail {
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 0.18115942rem 0.36231884rem;
  margin-top: 0.36231884rem;
  color: #fff;
  background: #ee662b;
  border-radius: 1.20772947rem;
  cursor: pointer;
}
.elasticframepayscore .adddemolist {
  font-size: 0.39rem;
  text-align: center;
}
.elasticframepayscore .adddemolist p {
  padding-top: 0.36rem;
}
.elasticframepayscore .adddemolist span {
  color: #009999;
}
.elasticframepayscore .adddemolist .adddemolist-btn {
  text-align: center;
  margin-top: 0.36rem;
  padding-bottom: 0.6rem;
}
.elasticframepayscore .adddemolist .adddemolist-btn .adddemolist-btn-list {
  display: inline-block;
  background: #009999;
  color: #ffffff;
  line-height: 1;
  padding: 0.24154589rem 0.48309179rem;
  border-radius: 1.20772947rem;
}
.elasticframepayscore .addnewsigntext {
  padding: 0.48rem;
  background: #fff;
  font-size: 0.36rem;
  color: #333333;
  padding: 1.08rem 0.48rem;
  width: 100%;
  text-align: center;
}
.elasticframepayscore .addnewsigntext .addnewsigntext_span {
  display: block;
  width: 100%;
  color: #de6c2b;
}
.elasticframepayscore .addnewsigntext p {
  display: block;
  width: 100%;
  font-size: 0.43rem;
}
.elasticframepayscore .elastcenter {
  width: 8.7rem;
  background: #fff;
  position: relative;
}
.elasticframepayscore h3 {
  height: 1.21rem;
  background: #009999;
  color: #fff;
  font-size: 0.39rem;
  line-height: 1.21rem;
  width: 100%;
  position: relative;
  text-align: center;
}
.elasticframepayscore .elast-close {
  display: block;
  width: 0.97rem;
  height: 1.21rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: url('../../static/images/closeIcon.png') no-repeat center center / 60% auto;
}
.elasticframepayscore .elast-text.morebtn {
  width: 100%;
  height: 0.36rem;
}
.elasticframepayscore .elast-text.morebtn p {
  background: #ed6a09;
  color: #ffffff;
  border-radius: 0.48309179rem;
  padding: 0.24154589rem 0.36231884rem;
}
.elasticframepayscore .elast-textbox.none {
  display: none;
}
.elasticframepayscore .elast-textbox {
  padding: 0.48rem;
  min-height: 3.38rem;
  background: #fff;
  font-size: 0.36rem;
  color: #666;
}
.elasticframepayscore .elast-textbox ul {
  overflow: hidden;
  text-align: center;
}
.elasticframepayscore .elast-textbox ul li {
  width: 33%;
  display: inline-block;
  text-align: center;
  margin-left: 10%;
  font-size: 0.39rem;
  border: 0.01811594rem solid #009999;
  margin-bottom: 0.48rem;
}
.elasticframepayscore .elast-textbox ul li .icon {
  width: 1.21rem;
  height: 1.21rem;
  margin: 0 auto;
  background: url('../../static/images/icon_integral.png') no-repeat center center / 100% 100%;
}
.elasticframepayscore .elast-textbox ul li .scorenum {
  color: #009999;
  opacity: 0.6;
  line-height: 1;
}
.elasticframepayscore .elast-textbox ul li .moneynum {
  color: #000000;
  padding-top: 0.12rem;
  padding-bottom: 0.24rem;
  line-height: 1;
}
.elasticframepayscore .elast-textbox ul li .buyscorebtn {
  background: #009999;
  color: #ffffff;
  line-height: 1;
  padding-top: 0.24rem;
  padding-bottom: 0.24rem;
}
.elasticframepayscore .elast-textbox .elast-buy-btn {
  width: 100%;
  text-align: center;
  padding-top: 0.72rem;
  padding-bottom: 0.36rem;
  overflow: hidden;
}
.elasticframepayscore .elast-textbox .elast-buy-btn span {
  padding: 0.14492754rem 0.48309179rem;
  background: #ee662b;
  color: #fff;
  border-radius: 1.20772947rem;
}
