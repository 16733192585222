.elastcenternewsign {
  width: 8.7rem;
  height: 8.23rem;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.singlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 222;
}
.singlayer .signlayer_tips {
  text-align: center;
  font-size: 0.31rem;
  color: #009999;
  line-height: 1;
  padding-top: 0.12rem;
}
.singlayer .signlayer_tips span {
  border-bottom: 0.01811594rem solid #009999;
  padding-bottom: 0.06rem;
}
.singlayer .elast-close {
  display: block;
  width: 0.97rem;
  height: 1.21rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: url('../../static/images/closeIcon.png') no-repeat center center / 60% auto;
}
.singlayer h3 {
  width: 100%;
  height: 1.21rem;
  background: #009999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #fff;
  font-size: 0.39rem;
  padding-left: 0.48rem;
  position: relative;
}
.singlayer .singlayer_textbox {
  background: #ffffff;
  min-height: 1.20772947rem;
}
.singlayer .singlayer_textbox .signlayer_title {
  font-size: 0.43rem;
  color: #ec6602;
  line-height: 1;
  text-align: center;
  padding: 0.74879227rem 0;
}
.singlayer .singlayer_textbox .signlayer_list {
  padding-left: 1.06rem;
}
.singlayer .singlayer_textbox .signlayer_list li::after {
  content: ' ';
  width: 0.34rem;
  height: 0.34rem;
  background: url(../../static/images/mall/mall_layer.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  left: -0.54rem;
}
.singlayer .singlayer_textbox .signlayer_list li {
  width: 100%;
  position: relative;
  margin-bottom: 0.54rem;
}
.singlayer .singlayer_textbox .signlayer_list li .signlayer_list_bnt {
  width: 1.36rem;
  height: 0.52rem;
  line-height: 0.52rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0rem;
  font-size: 0.27rem;
  text-align: center;
  background: #ec6602;
  color: #ffffff;
  border-bottom-left-radius: 0.24154589rem;
}
.singlayer .singlayer_textbox .signlayer_list li .signlayer_list_nums {
  text-align: right;
  width: 1.21rem;
  height: 0.48rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.53rem;
  right: 1.64rem;
  font-weight: bold;
  font-size: 0.43rem;
  color: #ec6602;
}
.singlayer .singlayer_textbox .signlayer_list li .signlayer_list_title {
  font-size: 0.39rem;
  color: #000000;
  line-height: 1;
}
.singlayer .singlayer_textbox .signlayer_list li .signlayer_list_text {
  padding-top: 0.19rem;
  font-size: 0.29rem;
  color: #686868;
  line-height: 1;
}
