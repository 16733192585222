.common-groupselectlist {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.common-groupselectlist .groupselectlist-box {
  width: 8.7rem;
  max-height: 0.97%;
  max-height: 80%;
  height: 60%;
  overflow: scroll;
  min-height: 3.62rem;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.common-groupselectlist .groupselectlist-box .group-hotlist-addgroup {
  margin: 0 0.38647343rem;
  background: #f9f9f9;
  color: #009999;
  font-size: 0.36rem;
  padding-left: 0.8rem;
  position: relative;
  line-height: 1;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
  margin-top: 0.43rem;
  margin-bottom: 0.48rem;
}
.common-groupselectlist .groupselectlist-box .group-hotlist-addgroup::after {
  content: ' ';
  top: 0;
  bottom: 0;
  left: 0.36rem;
  position: absolute;
  margin: auto;
  width: 0.31rem;
  height: 0.31rem;
  background: url(../../static/images/group/group-addicon.png) no-repeat top left / 100% 100%;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-scroll .groupselectlist-line {
  position: relative;
  height: 0.03rem;
  width: 7.92rem;
  left: 0.39rem;
  background: #c5c5c5;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-scroll.morscroll {
  overflow: scroll;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  min-height: 0.36rem;
  font-size: 0.43rem;
  line-height: 1;
  background: #009999;
  color: #ffffff;
  padding: 0.33816425rem 0;
  text-align: center;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-title .groupselectlist-close {
  width: 0.36rem;
  height: 0.36rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.39rem;
  margin: auto;
  background: url(../../static/images/group/group-closelayer.png) no-repeat top left / 100% 100%;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist {
  padding-top: 1.09rem;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li.active {
  border-bottom: none;
  padding-bottom: 0rem;
  padding-top: 0rem;
  margin: 0;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li.active .group-hotlist-li-title {
  background: #f8f8f8;
  padding-left: 0.39rem;
  padding-right: 0.39rem;
  border-bottom: 0.03019324rem solid #ebebeb;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li.active .group-hotlist-li-smalllist {
  margin-left: 0.39rem;
  margin-right: 0.39rem;
  display: block;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li.active .group-hotlist-smalllist-li:last-child {
  border-bottom: 0.03019324rem solid #f0f0f0;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li.active .group-hotlist-li-right::after {
  -webkit-transform: rotate(-180deg);
  -webkit-transition: 400ms;
  transition: 400ms;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li {
  overflow: hidden;
  margin-left: 0.39rem;
  margin-right: 0.39rem;
  border-bottom: 0.03019324rem solid #f0f0f0;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li .group-hotlist-li-title {
  width: 100%;
  overflow: hidden;
  padding-top: 0.43rem;
  padding-bottom: 0.43rem;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li .group-hotlist-li-smalllist {
  color: #686868;
  font-size: 0.36rem;
  display: none;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li .group-hotlist-li-smalllist .group-hotlist-smalllist-li {
  padding: 0.28985507rem 0;
  border-bottom: 0.01811594rem dotted #f0f0f0;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li .group-hotlist-li-left {
  width: 2.05rem;
  font-size: 0.36rem;
  line-height: 0.51rem;
  color: #333333;
  float: left;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li .group-hotlist-li-right {
  width: 5.8rem;
  font-size: 0.36rem;
  line-height: 0.51rem;
  padding-right: 0.48rem;
  color: #929292;
  position: relative;
  float: left;
}
.common-groupselectlist .groupselectlist-box .groupselectlist-hotlist .group-hotlist-li .group-hotlist-li-right::after {
  content: ' ';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transition: 400ms;
  transition: 400ms;
  right: 0;
  width: 0.27rem;
  height: 0.14rem;
  background: url(../../static/images/group/group-list-arrow.png) no-repeat center right / 100% 100%;
}
