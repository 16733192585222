.coursedata-scroll {
  width: 100%;
  height: 100%;
  padding-top: 0.36rem;
  background: #f9f9f9;
}
.coursedata-scroll .iscroll-padding {
  padding-left: 0.36rem;
  padding-right: 0.36rem;
  padding-bottom: 0;
}
.coursedata-scroll .down-footer {
  bottom: 0rem;
}
.coursedata-scroll .down-footer:after {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 0.02rem solid #e3e3e3;
  background: #e3e3e3;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.coursedata-scroll .typedown-li {
  width: 100%;
  height: 1.16rem;
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-iyems: center;
  color: #303030;
  font-size: 0.36rem;
  padding-right: 2.05rem;
}
.coursedata-scroll .typedown-li span {
  display: block;
  width: 0.68rem;
  height: 0.68rem;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  border: 0.02415459rem solid #cacaca;
  margin-right: 0.19rem;
}
.coursedata-scroll .typedown-li.active span {
  border: none;
  background: url('../../static/images/labelactive.png') no-repeat center center / 100% 100%;
}
.coursedata-scroll .typedown-li::after {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 0.02415459rem dashed #cacaca;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
}
.coursedata-scroll .typePreview {
  width: 1.17rem;
  height: 0.58rem;
  position: absolute;
  bottom: 0;
  right: 0.48rem;
  top: 0;
  margin: auto;
  font-size: 0.32rem;
  border: 0.015rem solid #cacaca;
  border-radius: 0.58rem;
}
.coursedata-scroll .typePreview a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
