.none {
  display: none;
}
.home {
  padding-bottom: 1.21rem;
}
#footerexternalnew.nointer li {
  width: 25%;
}
#footerexternalnew .singlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 222;
  display: none;
}
#footerexternalnew .singlayer .singlayer_textbox {
  background: #ffffff;
  min-height: 1.20772947rem;
}
#footerexternalnew .singlayer .singlayer_textbox .signlayer_title {
  font-size: 0.43rem;
  color: #ec6602;
  line-height: 1;
  text-align: center;
  padding: 0.74879227rem 0;
}
#footerexternalnew .singlayer .singlayer_textbox .signlayer_list {
  padding-left: 1.06rem;
}
#footerexternalnew .singlayer .singlayer_textbox .signlayer_list li::after {
  content: ' ';
  width: 0.34rem;
  height: 0.34rem;
  background: url(../../static/images/mall/mall_layer.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  left: -0.54rem;
}
#footerexternalnew .singlayer .singlayer_textbox .signlayer_list li {
  width: 100%;
  position: relative;
  margin-bottom: 0.54rem;
}
#footerexternalnew .singlayer .singlayer_textbox .signlayer_list li .signlayer_list_nums {
  text-align: right;
  width: 1.21rem;
  height: 0.48rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.53rem;
  font-weight: bold;
  font-size: 0.43rem;
  color: #ec6602;
}
#footerexternalnew .singlayer .singlayer_textbox .signlayer_list li .signlayer_list_title {
  font-size: 0.39rem;
  color: #000000;
  line-height: 1;
}
#footerexternalnew .singlayer .singlayer_textbox .signlayer_list li .signlayer_list_text {
  padding-top: 0.19rem;
  font-size: 0.29rem;
  color: #686868;
  line-height: 1;
}
#footerexternalnew {
  width: 100%;
  height: 1.33rem;
  border-top: 0.01811594rem #e3e3e3 solid;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 33;
  z-index: 100;
  -webkit-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
}
#footerexternalnew ul {
  width: 100%;
  height: 100%;
}
#footerexternalnew li.three {
  width: 33%;
}
#footerexternalnew li {
  width: 20%;
  height: 100%;
  position: relative;
}
#footerexternalnew li .gohome_add {
  width: 1.41rem;
  height: 1.44rem;
  background: url(../../static/images/homeextel_new.png) no-repeat top left / 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0.12rem;
}
#footerexternalnew li a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
#footerexternalnew .footer-contain {
  font-size: 0.24rem;
  color: #8c8c8c;
}
#footerexternalnew .footer-icon {
  display: block;
  width: 100%;
  height: 0.48rem;
  margin-bottom: 0.12rem;
  margin-top: 0.18rem;
}
#footerexternalnew .footer-text {
  text-align: center;
  line-height: 1;
}
#footerexternalnew .footer-text.active {
  color: #fe5b00;
}
#footerexternalnew .footeradd_home .footer-icon {
  background: url(../../static/images/homepagelist/layer_iconhome.png) no-repeat center center / auto 100%;
}
#footerexternalnew .footeradd_home .footer-icon.active {
  background: url(../../static/images/homepagelist/layer_iconhome_active.png) no-repeat center center / auto 100%;
}
#footerexternalnew .footeradd_1 .footer-icon {
  background: url('../../static/images/homepagelist/layer_icon1.png') no-repeat center center / auto 100%;
}
#footerexternalnew .footeradd_1 .footer-icon.active {
  background: url('../../static/images/homepagelist/layer_icon1_active.png') no-repeat center center / auto 100%;
}
#footerexternalnew .footeradd_2 .footer-icon {
  background: url('../../static/images/homepagelist/layer_icon2.png') no-repeat center center / auto 100%;
}
#footerexternalnew .footeradd_2 .footer-icon.active {
  background: url('../../static/images/homepagelist/layer_icon2_active.png') no-repeat center center / auto 100%;
}
#footerexternalnew .footeradd_3 .footer-icon {
  background: url('../../static/images/homepagelist/layer_icon4.png') no-repeat center center / auto 100%;
}
#footerexternalnew .footeradd_3 .footer-icon.active {
  background: url('../../static/images/homepagelist/layer_icon4_active.png') no-repeat center center / auto 100%;
}
#footerexternalnew .footeradd_4 .footer-icon {
  background: url('../../static/images/homepagelist/layer_icon3.png') no-repeat center center / auto 100%;
}
#footerexternalnew .footeradd_4 .footer-icon.active {
  background: url('../../static/images/homepagelist/layer_icon3_active.png') no-repeat center center / auto 100%;
}
#firstooter::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e3e3e3;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
