.nocontent {
  text-align: center;
  width: 100%;
  height: 0.48rem;
  font-size: 0.31rem;
  color: #cccccc;
  padding-top: 1.21rem;
}
.eshop-list {
  padding: 0 0.36231884rem 0.02415459rem;
  background: none;
}
.eshop-list li {
  width: 100%;
  padding: 0.36231884rem 0;
  position: relative;
  padding-left: 3.32rem;
  min-height: 2.42rem;
}
.eshop-list li::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e8e8e8;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
}
.eshop-list .develist-img {
  display: block;
  width: 3.02rem;
  height: 1.69rem;
  overflow: hidden;
  position: absolute;
  left: 0rem;
  top: 0.36rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
}
.eshop-list .develist-img img {
  width: auto;
  height: 101%;
}
.eshop-list .develist-img i.gary {
  background: rgba(0, 0, 0, 0.5);
}
.eshop-list .develist-img i.green {
  background-color: #3f9798;
}
.eshop-list .develist-img i {
  position: absolute;
  bottom: 0;
  left: 0.06rem;
  bottom: 0.06rem;
  font-style: normal;
  font-size: 0.24rem;
  background: #ec6602;
  line-height: 1;
  color: #ffffff;
  border-radius: 0.48309179rem;
  padding: 0.06038647rem 0.12077295rem;
}
.eshop-list .develist-img b {
  position: absolute;
  bottom: 0;
  right: 0.06rem;
  bottom: 0.06rem;
  font-style: normal;
  font-size: 0.24rem;
  background: #ec6602;
  line-height: 1;
  color: #ffffff;
  font-weight: inherit;
  border-radius: 0.48309179rem;
  padding: 0.06038647rem 0.12077295rem;
  text-align: center;
  max-width: 1.21rem;
  white-space: nowrap;
  overflow: hidden;
}
.eshop-list .develist-title {
  font-size: 0.36rem;
  color: #333333;
  font-weight: 400;
}
.eshop-list .tipslist {
  overflow: hidden;
  padding: 0.02415459rem 0;
  margin-top: 0.05rem;
  padding-bottom: 0.12rem;
  display: inline-block;
  position: relative;
  width: 100%;
  padding-right: 2.17rem;
}
.eshop-list .tipslist .eshop-cards {
  background: #ccc;
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 0.63rem;
  top: 0.24rem;
  font-size: 0.24rem;
  color: #929292;
  background: url('../../../static/images/eshop/eshop_cardicon.png') no-repeat left 0rem center / 0.471rem auto;
}
.eshop-list .tipslist span {
  min-height: 0.39rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  float: left;
  padding: 0 0.16908213rem;
  color: #929292;
  font-size: 0.24rem;
  margin-right: 0.24rem;
  margin-top: 0.24rem;
}
.eshop-list .tipslist span::after {
  content: '';
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0.02415459rem solid #d8d8d8;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  border-radius: 0.97rem;
}
.eshop-list .tipslist .tip-name {
  padding-left: 0.43rem;
  background: url('../../../static/images/peopleIcon.png') #f3f3f3 no-repeat left 0.16rem center / 0.2rem auto;
}
.eshop-list .tipslist .tip-time {
  padding-left: 0.46rem;
  background: url('../../../static/images/dateIcon.png') #f3f3f3 no-repeat left 0.16rem center / 0.22rem auto;
}
.eshop-list .tipslist .tip-nums {
  padding-left: 0.46rem;
  background: url('../../../static/images/playnumsicon.png') #f3f3f3 no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew {
  padding-left: 0.46rem;
  background: url('../../../static/images/mall/mall_rank_icon2.png') #f3f3f3 no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.completeOne,
.eshop-list .tipslist .tip-addnew.classCsMicroLecture,
.eshop-list .tipslist .tip-addnew.classCloudReading,
.eshop-list .tipslist .tip-addnew.classCloudClassroom {
  color: #fe5b00;
  background: rgba(254, 91, 0, 0.06);
  padding-left: 0.46rem;
  background: url('../../../static/images/mall/icon_complete_1.png') #f3f3f3 no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.classCsMicroLecture {
  background: url('../../../static/images/mall/icon_cs_micro_lecture.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.classCloudReading {
  background: url('../../../static/images/mall/icon_cloud_reading.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.classCloudClassroom {
  background: url('../../../static/images/mall/icon_cloud_classroom.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .cslistbox {
  width: 100%;
  overflow: hidden;
  padding-bottom: 0.24rem;
}
.eshop-list .tipslist span.tip-addnew.coursepay::after {
  content: '';
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0.02415459rem solid #fe5b00;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  border-radius: 0.97rem;
}
.eshop-list .tipslist .tip-addnew.coursepayno {
  padding-left: 0.46rem;
  background: url('../../../static/images/healthineers_icon_payfree_1.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.coursepay {
  padding-left: 0.46rem;
  color: #fe5b00;
  background: url('../../../static/images/healthineers_icon_payfree_2_yellor.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.islookno {
  padding-left: 0.46rem;
  background: url('../../../static/images/healthineers_icon_payfree_1.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.islook {
  padding-left: 0.46rem;
  background: url('../../../static/images/healthineers_icon_payfree_2.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.playcount {
  padding-left: 0.46rem;
  background: url('../../../static/images/mall/mall_list_icon2.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.completeTwo {
  color: #009999;
  padding-left: 0.46rem;
  background: rgba(0, 153, 153, 0.06);
  background: url('../../../static/images/mall/icon_complete_2.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist .tip-addnew.viewTwo {
  color: #009999;
  background: rgba(0, 153, 153, 0.06);
  padding-left: 0.46rem;
  background: url('../../../static/images/mall/icon_view_2.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist span.tip-addnew.viewTwo::after,
.eshop-list .tipslist span.tip-addnew.completeTwo::after {
  content: '';
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  color: #009999;
  border: 0.01811594rem solid #009999;
  background: rgba(0, 153, 153, 0.06);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  border-radius: 0.97rem;
}
.eshop-list .tipslist .tip-addnew.viewOne {
  color: #fe5b00;
  background: rgba(254, 91, 0, 0.06);
  padding-left: 0.46rem;
  background: url('../../../static/images/mall/icon_view_1.png') no-repeat left 0.16rem center / 0.23rem auto;
}
.eshop-list .tipslist span.tip-addnew.viewOne::after,
.eshop-list .tipslist span.tip-addnew.completeOne::after {
  content: '';
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  color: #fe5b00;
  border: 0.01811594rem solid #fe5b00;
  background: rgba(254, 91, 0, 0.06);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  border-radius: 0.97rem;
}
