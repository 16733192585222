.typedown-list li {
  width: 100%;
}
.typedown-list .typedown-title {
  width: 100%;
  height: 1.15rem;
  position: relative;
  padding-left: 0.39rem;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  color: #303030;
  font-size: 0.36rem;
  font-weight: 700;
}
.typedown-list .typedown-title.active_first {
  color: #009999;
}
.typedown-list .typedown-title:after {
  content: "";
  display: block;
  width: 100%;
  height: 0.02rem;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  background: #cacaca;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
}
.typedown-list .typedown-stitle {
  color: #303030;
  font-size: 0.36rem;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
  position: relative;
}
.typedown-list .typedown-stitle.active_second {
  color: #fe5c01;
}
.typedown-list .typedown-stitle.active_second i {
  background: #fe5c01;
}
.typedown-list .typedown-stitle::after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 0.02415459rem dashed #d0d0d0;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
}
.typedown-list .scend_line {
  display: inline-block;
  width: 0.07rem;
  height: 0.33rem;
  background: #a5a5a5;
  border-radius: 0.07rem;
  margin-right: 0.19rem;
  vertical-align: middle;
  margin-top: -0.05rem;
}
.typedown-list .down_scendbox {
  padding-left: 0.39rem;
  position: relative;
}
.typedown-list .down_scendbox.active::after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 0.02415459rem solid #e8e8e8;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
}
.typedown-list .down_scendbox:last-child .typedown-stitle::after {
  display: none;
}
.typedown-list .down_scendbox:first-child .typedown-stitle::after {
  display: block;
}
.typedown-list .question-titlearrow {
  display: block;
  width: 0.36rem;
  height: 0.36rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0.48rem;
  margin: auto;
  -webkit-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: url("../../static/images/downdroparrow.png") no-repeat center center / 96% auto;
}
.typedown-list .question-titlearrow.active {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.typedown-list .question-second {
  display: block;
  width: 0.24rem;
  height: 0.14rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0.54rem;
  margin: auto;
  -webkit-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  background: url("../../static/images/detailsarrow_two.png") no-repeat center center / 96% auto;
}
.typedown-list .question-second.active {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.typedown-list li.active .typedown-title {
  color: #009999;
}
.typedown-list li.active .question-titlearrow {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.typedown-list .typedown-list {
  position: relative;
  display: none;
  padding-left: 0.39rem;
  -webkit-box-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.02) inset;
  box-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.02) inset;
}
.typedown-list .typedown-list .typedown-li {
  width: 100%;
  min-height: 1.16rem;
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-iyems: center;
  color: #303030;
  font-size: 0.36rem;
  padding-right: 3.14rem;
  padding-top: 0.24rem;
  padding-bottom: 0.24rem;
}
.typedown-list .typedown-list .typedown-li span {
  display: block;
  width: 0.68rem;
  height: 0.68rem;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  border: 0.02415459rem solid #cacaca;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.typedown-list .typedown-list .typedown-li .readOnly {
  background: #eae9e9;
}
.typedown-list .typedown-list .typedown-li.active span {
  border: none;
  background: url("../../static/images/labelactive.png") no-repeat center center / 100% 100%;
}
.typedown-list .typedown-list .typedown-li::after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 0.02415459rem dashed #cacaca;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
}
.typedown-list .typedown-list .typedown-li:last-child::after {
  display: none;
}
.typedown-list .second-select {
  display: none;
}
.typedown-list .second-select.active {
  display: block;
}
.typedown-list .typedown-list.active {
  display: block;
}
.typedown-list .typedown-list:after {
  content: "";
  display: block;
  width: 100%;
  height: 0.02rem;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  background: #cacaca;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
}
.typedown-list .typePreview {
  width: 1.17rem;
  height: 0.58rem;
  position: absolute;
  bottom: 0;
  right: 0.48rem;
  top: 0;
  margin: auto;
  font-size: 0.32rem;
  border: 0.015rem solid #cacaca;
  border-radius: 0.58rem;
}
.typedown-list .typePreview a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
.typedown-list .typeDownBtn {
  width: 1.17rem;
  height: 0.58rem;
  position: absolute;
  bottom: 0;
  right: 1.81rem;
  top: 0;
  margin: auto;
  color: #333;
  font-size: 0.32rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
  border: 0.015rem solid #cacaca;
  border-radius: 0.58rem;
}
