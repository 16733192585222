.common-groupteachlistli {
  position: relative;
  overflow: hidden;
  padding: 0.32608696rem 0;
  border-bottom: 0.01811594rem dotted #eaeaea;
}
.common-groupteachlistli div {
  float: left;
}
.common-groupteachlistli .group-li-name {
  width: 1.93rem;
  font-size: 0.36rem;
  color: #333;
  text-align: left;
  min-height: 0.36rem;
  line-height: 0.56rem;
}
.common-groupteachlistli .group-li-type {
  width: 1.11rem;
  height: 0.31rem;
  line-height: 1;
  position: absolute;
  font-size: 0.31rem;
  left: 2.03rem;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #929292;
  text-align: left;
}
.common-groupteachlistli .group-li-lines.active {
  padding-right: 0.9057971rem;
}
.common-groupteachlistli .group-li-lines.active .group-li-lines-line {
  width: 5.17rem;
}
.common-groupteachlistli .group-li-lines.active .group-li-lines-state {
  display: none;
}
.common-groupteachlistli .group-li-lines.active .group-li-lines-nums {
  right: 0;
}
.common-groupteachlistli .group-li-lines.hover {
  padding-right: 0.9057971rem;
}
.common-groupteachlistli .group-li-lines.hover .group-li-lines-line.groupinfo {
  width: 5.17rem;
}
.common-groupteachlistli .group-li-lines.hover .group-li-lines-line {
  width: 3.96rem;
  width: 5.17rem;
}
.common-groupteachlistli .group-li-lines.hover .group-li-lines-state {
  display: none;
}
.common-groupteachlistli .group-li-lines {
  line-height: 1;
  position: absolute;
  font-size: 0;
  left: 3.16rem;
  height: 0.19rem;
  top: 0;
  bottom: 0;
  margin: auto;
  padding-right: 2.11352657rem;
}
.common-groupteachlistli .group-li-lines .group-li-lines-line {
  width: 3.96rem;
  height: 0.19rem;
  border: #eb9654 0.01811594rem solid;
  display: inline-block;
  border-radius: 1.20772947rem;
}
.common-groupteachlistli .group-li-lines .group-li-lines-line i {
  width: 0%;
  background: #ec6602;
  display: inline-block;
  height: 100%;
  border-radius: 1.20772947rem;
}
.common-groupteachlistli .group-li-lines .group-li-lines-nums {
  font-size: 0.29rem;
  color: #e47016;
  margin-left: 0.14rem;
  position: absolute;
  right: 0;
  top: 0;
  top: -0.05rem;
}
.common-groupteachlistli .group-li-lines .group-li-lines-state.active {
  color: #000000;
}
.common-groupteachlistli .group-li-lines .group-li-lines-state {
  font-size: 0.29rem;
  color: #e47016;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  top: -0.05rem;
}
.common-groupteachlistli .group-li-lines .group-li-lines-group {
  font-size: 0.29rem;
  color: #e47016;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  top: -0.05rem;
}
