.loadinglayer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.loadinglayer .loadinglayer {
  width: 2.41545894rem;
  height: 0.72463768rem;
  line-height: 0.72463768rem;
  color: #000;
  background: #ffffff;
  font-size: 0.34rem;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  border-radius: 100px;
  animation: breath 2s ease-in-out infinite alternate;
}
@keyframes breath {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
