.course-nolist {
  font-size: 0.31rem;
  color: #333333;
  text-align: center;
  padding-top: 1.21rem;
}
.cslist_tips {
  padding: 0.24154589rem 0.36231884rem;
  border-top: 0.01811594rem solid rgba(0, 0, 0, 0.07);
  position: relative;
  background-color: #fff;
}
.cslist_content {
  color: #686868;
  margin-top: 0.09661836rem;
}
.cslist_btnlist {
  position: absolute;
  top: 0.12rem;
  right: 0.72rem;
}
.cslist_btnlist div {
  float: left;
  margin-right: 0.12rem;
  margin-left: 0.12rem;
}
.cslist_btnlist .cslist_gochange {
  color: #fff;
  background-color: #41999a;
  border-radius: 1.20772947rem;
  padding: 0.07246377rem 0.19323671rem;
  margin-top: 0.12077295rem;
  font-size: 0.27rem;
}
.cslist_btnlist .cslist_state {
  color: #666;
  margin-top: 0.17rem;
  font-size: 0.27rem;
  border: 0.01811594rem solid #41999a;
  border-radius: 1.20772947rem;
  padding: 0.07246377rem 0.31400966rem;
  margin-top: 0.12077295rem;
}
.cslist_btnlist .active {
  color: #41999a;
}
.drop-list {
  padding: 0.12077295rem 0.36231884rem 0;
}
.drop-list .iscsclass .droplist-li p.sectext {
  width: 60% !important;
  word-break: break-all;
}
.drop-list .iscsclass .droplist-li p.sectext .hasnewcourse {
  width: 0.59rem;
  height: 0.29rem;
  margin-left: 0.14rem;
  background: url(../../static/images/newcourse_icon.png) no-repeat top left / 100% 100%;
  display: inline-block;
}
.drop-list li {
  margin-top: 0.36rem;
  border: 0.01811594rem solid #e0e0e0;
  -webkit-box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.05);
  background: #f8f8f8;
  padding-bottom: 0.01rem;
}
.drop-list .typelist_ultrasonic_nums.addnewicon {
  right: 1.57rem;
}
.drop-list .typelist_ultrasonic_nums {
  position: absolute;
  top: 0.14rem;
  right: 0;
  bottom: 0;
  margin: auto;
  right: 0.6rem;
  width: 2.17rem;
  height: 0.48rem;
  font-size: 0.31rem;
}
.drop-list .typelist_ultrasonic_nums .manage-names-live {
  width: 1.21rem;
  font-size: 0;
  line-height: 0;
  padding-top: 0.12rem;
  float: left;
  flex-direction: column;
  justify-content: center;
  justify-content: space-around;
}
.drop-list .typelist_ultrasonic_nums .manage-names-live span {
  width: 1.09rem;
  height: 0.14rem;
  display: flex;
  background: none;
  border-radius: 0.09661836rem;
  border: 0.01811594rem #ec6500 solid;
  display: inline-block;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.drop-list .typelist_ultrasonic_nums .manage-names-live span i {
  width: 1.21rem;
  height: 0.12rem;
  background: #ec6500;
  position: relative;
  top: -0.01rem;
  display: inline-block;
  border-radius: 0 0.09661836rem 0.09661836rem 0.09661836rem;
}
.drop-list .drop-title.iscs {
  padding-right: 30%;
}
.drop-list .drop-title {
  width: 100%;
  height: 1.04rem;
  padding-left: 0.17rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.36rem;
  color: #333333;
  position: relative;
  font-weight: 400;
}
.drop-list .drop-title .hasnewcourse {
  width: 0.59rem;
  height: 0.29rem;
  margin-left: 0.14rem;
  background: url(../../static/images/newcourse_icon.png) no-repeat top left / 100% 100%;
  display: inline-block;
}
.drop-list .drop-title.active {
  color: #069b9b;
}
.drop-list .drop-secondlist {
  background: #fff;
  display: none;
  position: relative;
  z-index: 9;
}
.drop-list .drop-secondlist::before {
  content: '';
  display: block;
  width: 100%;
  height: 0.05rem;
  background: #e0e0e0;
  position: absolute;
  top: 0rem;
  left: 0rem;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(1, 0.25);
  transform: scale(1, 0.25);
}
.drop-list .drop-secondlist.active {
  display: block;
}
.drop-list .droplist-li {
  width: 100%;
  padding-left: 0.34rem;
  padding-right: 0.85rem;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
  line-height: 0.48rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #686868;
  font-size: 0.36rem;
  position: relative;
  border-bottom: 0.01449275rem dotted #e2e2e2;
  word-break: break-all;
}
.drop-list .droplist-li p {
  width: 70%;
  word-break: break-all;
}
.drop-list .droplist-li p.sectext {
  width: 90% !important;
  word-break: break-all;
}
.drop-list .droplist-li:last-child {
  border: none;
}
.drop-list .droplist-arrow {
  display: block;
  width: 0.31rem;
  height: 0.31rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0.36rem;
  margin: auto;
  background: url('../../static/images/downdroparrow.png') no-repeat center center / 100% 100%;
}
.drop-list .drop-titlearrow {
  display: block;
  width: 0.27rem;
  height: 0.14rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0.36rem;
  margin: auto;
  background: url('../../static/images/droparrow.png') no-repeat center center / cover;
  -webkit-transition: transform .3s ease;
  transition: transform .3s ease;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.drop-list .drop-titlearrow.active {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
