.special {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  left: 0rem;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
.special .special_change {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  color: #ffffff;
  font-size: 0.39rem;
  padding: 0.14492754rem 0.28985507rem;
  line-height: 1;
}
.special .special_textbox {
  height: 6.04rem;
  width: 100%;
  padding: 0 0.84541063rem;
  color: #ffffff;
  font-size: 0.39rem;
  line-height: 0.68rem;
}
.special .special_textbox .special_textbox_title {
  padding-bottom: 0.29rem;
}
.special .special_btnlist {
  position: absolute;
  bottom: 0;
  bottom: 1.52rem;
  left: 0;
  color: #ffffff;
  text-align: center;
  width: 100%;
}
.special .special_btnlist .special_btnlist_OK {
  width: 2.78rem;
  line-height: 1.04rem;
  height: 1.04rem;
  color: #ffffff;
  background: #e47016;
  border-radius: 2.41545894rem;
  display: inline-block;
  margin-right: 1.59rem;
}
.special .special_btnlist .special_btnlist_NO {
  width: 2.78rem;
  line-height: 1.04rem;
  height: 1.04rem;
  border: 0.01811594rem solid #ffffff;
  border-radius: 2.41545894rem;
  display: inline-block;
}
