.common-talkli {
  min-height: 100px;
  width: 4.35rem;
  height: 5.64rem;
  padding-top: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
  border: 0.01811594rem #cccccc solid;
  float: left;
  position: relative;
  margin-left: 0.43rem;
  margin-bottom: 0.39rem;
}
.common-talkli .talkli_img {
  width: 4.35rem;
  width: 100%;
  height: 3.02rem;
  position: relative;
  overflow: hidden;
}
.common-talkli .talkli_img img {
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}
.common-talkli .talkli_title {
  line-height: 0.48rem;
  font-size: 0.36rem;
  padding-top: 0.27rem;
  padding-left: 0.31rem;
  padding-right: 0.31rem;
  padding-bottom: 0rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.common-talkli .talkli_morearrow {
  width: 0.36rem;
  height: 0.36rem;
  position: absolute;
  background: url(../../static/images/notice/morarrow.png) no-repeat top left / 100% 100%;
  right: 0.31rem;
  bottom: 0.31rem;
}
.common-talkli .talkli_nums {
  color: #929292;
  font-size: 0.31rem;
  line-height: 1;
  max-width: 3.32rem;
  position: absolute;
  left: 0.31rem;
  bottom: 0.31rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.common-talkli .talkli_nums .talk_li_playcount {
  position: relative;
  padding-left: 0.51rem;
  color: #929292;
  font-size: 0.34rem;
  line-height: 0.39rem;
  margin-left: 0.24rem;
}
.common-talkli .talkli_nums .talk_li_playcount::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 0.37rem;
  height: 0.37rem;
  background: url(../../static/images/playnumsicon.png) no-repeat top left / 100% 100%;
}
