.aicontent {
  padding: 0rem 0.36231884rem;
  margin-bottom: 0.39rem;
}
.aicontent .loadingaidemo {
  color: #cccccc;
  font-size: 0.34rem;
  display: inline-block;
  padding-right: 0.97rem;
  position: relative;
}
.aicontent .loadingaidemo .loadingicon {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 0.97rem;
  height: 0.97rem;
  background: url(../../static/images/ai/loading.gif) no-repeat top left / 100% 100%;
}
.aicontent .ai_surveryboxlayer {
  position: fixed;
  top: 0;
  left: 0;
}
.aicontent .ai_survery_contentbox {
  width: 100%;
  text-align: center;
  line-height: 1;
  margin-top: 0.24rem;
  position: relative;
  padding-bottom: 0.6rem;
}
.aicontent .ai_survery_contentbox .gosurvery {
  position: absolute;
  width: 1.57004831rem;
  height: 0.30193237rem;
  background: url(../../static/images/ai/ai_fankui.png) no-repeat top left / 100% 100%;
  bottom: 0;
  right: 0;
  color: #009999;
  font-size: 0.28985507rem;
  text-align: right;
  line-height: 0.30193237rem;
  cursor: pointer;
}
.aicontent .ai_survery_contentbox .ai_survery_contentbox_left.active {
  color: #ffffff;
  background: #009999;
}
.aicontent .ai_survery_contentbox .ai_survery_contentbox_right.active {
  color: #ffffff;
  background: #009999;
}
.aicontent .ai_survery_contentbox .ai_survery_contentbox_left.active::before {
  content: '';
  display: inline-block;
  width: 0.42270531rem;
  height: 0.42270531rem;
  background: url(../../static/images/ai/ai_icon1_2.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.30193237rem;
  margin: auto;
}
.aicontent .ai_survery_contentbox .ai_survery_contentbox_right.active::before {
  content: '';
  display: inline-block;
  width: 0.42270531rem;
  height: 0.42270531rem;
  background: url(../../static/images/ai/ai_icon2_2.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.30193237rem;
  margin: auto;
}
.aicontent .ai_survery_contentbox .ai_survery_contentbox_left::before {
  content: '';
  display: inline-block;
  width: 0.42270531rem;
  height: 0.42270531rem;
  background: url(../../static/images/ai/ai_icon1_1.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.30193237rem;
  margin: auto;
}
.aicontent .ai_survery_contentbox .ai_survery_contentbox_right::before {
  content: '';
  display: inline-block;
  width: 0.42270531rem;
  height: 0.42270531rem;
  background: url(../../static/images/ai/ai_icon2_1.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.30193237rem;
  margin: auto;
}
.aicontent .ai_survery_contentbox .ai_survery_contentbox_left {
  display: inline-block;
  padding-top: 0.18rem;
  padding-left: 0.85rem;
  padding-bottom: 0.18rem;
  padding-right: 0.41rem;
  position: relative;
  color: #000000;
  font-size: 0.41062802rem;
  margin-right: 0.36231884rem;
  border-radius: 0.60386473rem;
  border: 0.01811594rem #009999 solid;
  cursor: pointer;
}
.aicontent .ai_survery_contentbox .ai_survery_contentbox_right {
  display: inline-block;
  padding-top: 0.18rem;
  padding-left: 0.85rem;
  padding-bottom: 0.18rem;
  padding-right: 0.41rem;
  position: relative;
  color: #000000;
  font-size: 0.41062802rem;
  border-radius: 0.60386473rem;
  border: 0.01811594rem #009999 solid;
  cursor: pointer;
}
.aicontent .aicontent_content {
  background: #ffffff;
  border-radius: 0.24154589rem;
  padding: 0.38647343rem 0.60386473rem;
}
.aicontent .aicontent_content .ai_list .ai_list_li {
  font-size: 0.39rem;
  line-height: 0.63rem;
  margin-bottom: 0.39rem;
}
.aicontent .aicontent_content .ai_list .ai_list_li a {
  display: inline;
  color: #009999;
  position: relative;
  margin-left: 0.12rem;
}
.aicontent .aicontent_content .ai_list .ai_list_li img {
  width: 0.39rem;
  height: 0.39rem;
  display: inline-block;
  position: relative;
  top: -0.04rem;
}
.aicontent .aicontent_content .ai_list .ai_list_li span::before {
  position: absolute;
  content: ' ';
  width: 0.42rem;
  height: 0.42rem;
  background: url(../../static/images/ai/ai_video_icon.png) no-repeat top left / 100% 100%;
  left: 0;
  top: 0rem;
}
.aicontent .aicontent_content .ai_list .ai_list_li span {
  color: #009999;
  position: relative;
  padding-left: 0.51rem;
  margin-left: 0.12rem;
}
.aicontent .aicontent_content .ai_content_title {
  padding-left: 0.72rem;
  position: relative;
  color: #009999;
  font-size: 0.43rem;
  font-weight: bold;
  margin-bottom: 0.39rem;
}
.aicontent .aicontent_content .ai_content_title::before {
  position: absolute;
  content: ' ';
  width: 0.51rem;
  height: 0.47rem;
  background: url(../../static/images/ai/ai_title_icon.png) no-repeat top left / 100% 100%;
  left: 0;
  bottom: 0;
  margin: 0;
  top: 0;
}
