.newlayer {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 33;
}
.hotdown-list {
  padding: 0 0.36231884rem;
}
.hotdown-list li {
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
  padding-left: 2.17rem;
  padding-right: 0.48rem;
  min-height: 3.14rem;
  position: relative;
}
.hotdown-list li::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e8e8e8;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0rem;
  left: 0rem;
}
.hotdown-list .hotdown-img {
  display: block;
  width: 1.81rem;
  height: 2.17rem;
  position: absolute;
  top: 0.48rem;
  left: 0rem;
  overflow: hidden;
  background: #f9f9f9;
}
.hotdown-list .hotdown-img img {
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}
.hotdown-list .hotdown-label {
  position: absolute;
  bottom: 0.48rem;
  left: 2.17rem;
}
.hotdown-list .hotdown-label span {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 0.6rem;
  font-size: 0.31rem;
  float: left;
  color: #929292;
  padding-left: 0.48rem;
}
.hotdown-list .hotdown-label .hotdown-top {
  color: #1ca4a4;
  padding-left: 0.41rem;
  background: url('../../static/images/hotdownIcon_01.png') no-repeat left top / 0.26rem auto;
}
.hotdown-list .hotdown-label .gotdown-nums {
  background: url('../../static/images/hotdownIcon_02.png') no-repeat left center / 0.38rem auto;
}
.hotdown-list .hotdown-title {
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.53rem;
  padding-top: 0.12rem;
}
.hotdown-list .hotdownStar {
  width: 1.33rem;
  height: 0.91rem;
  position: absolute;
  top: 0.48rem;
  right: 0rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #929292;
  font-size: 0.31rem;
}
.hotdown-list .hotdownStar div {
  position: absolute;
  right: 0rem;
}
.hotdown-list .hotdownStar::after {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  left: 0rem;
  top: 0rem;
  bottom: 0;
  border-left: 0.02415459rem dashed #d9d9d9;
  -webkit-transform: scale(0.5, 1);
  transform: scale(0.5, 1);
}
.hotdown-list .starcontain {
  display: block;
  width: 0.42rem;
  height: 0.42rem;
  margin: 0 auto 0.14492754rem;
  background: url('../../static/images/hotdownStar.png') no-repeat center center / 98% auto;
}
.hotdown-list .hotdownStar.active .starcontain {
  background: url('../../static/images/hotdownStar_active.png') no-repeat center center / 98% auto;
}
.hotdown-list .previewBtn {
  width: 1.17rem;
  height: 0.58rem;
  background: url('../../static/images/previewbtn.png') no-repeat center center / 100% auto;
  position: absolute;
  bottom: 0.43rem;
  right: 0rem;
}
.hotdown-list .previewBtn.godown {
  width: 1.17rem;
  height: 0.58rem;
  background: url('../../static/images/previewbtngodown.png') no-repeat center center / 100% auto;
  position: absolute;
  bottom: 0.43rem;
  right: 0rem;
}
