.common_sysinfo {
  font-size: 0.36rem;
  min-height: 100px;
  padding-top: 0.12rem;
  padding-left: 0.72rem;
  padding-right: 0.72rem;
  background: none;
  color: #1a1a1a;
  background: #ffffff;
}
.common_sysinfo .elasticframe {
  height: 100vh;
}
.common_sysinfo .elastcenter {
  max-height: 80%;
  overflow: hidden;
  position: relative;
}
.common_sysinfo .elastcenter h3 {
  position: absolute;
  top: 0;
  left: 0;
}
.common_sysinfo .elastcenter .elastcenterbox {
  padding-top: 1.21rem;
}
.common_sysinfo .elastcenter .elastcenterbox .masktextplaytitle {
  padding: 0 0.36231884rem;
  text-align: left;
  white-space: pre-wrap;
}
.common_sysinfo .elastcenter .elastcenterbox .masktextplaytitle a {
  display: inline;
}
.common_sysinfo .sysnotice_li_title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 0.39rem;
  color: #000000;
  line-height: 1;
  padding-top: 0.36rem;
}
.common_sysinfo .sysnotice_li_title a {
  display: inline;
}
.common_sysinfo .sysnotice_li_title span.titles {
  font-weight: 600;
  padding-right: 0.36231884rem;
}
.common_sysinfo .sysnotice_li_date {
  padding-top: 0.34rem;
  padding-bottom: 0.43rem;
  font-size: 0.31rem;
  line-height: 1;
  color: #adadad;
  border-bottom: 0.01811594rem solid #ececec;
}
