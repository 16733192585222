.ailanding {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
  background: #ccc;
  background: url(../../static/images/ai/ai_bg.jpg) no-repeat top left / 100% 100%;
}
.ailanding .ailading_logo {
  width: 2.33rem;
  height: 0.54rem;
  background: url(../../static/images/ai/ai_logo.png) no-repeat top left / 100% 100%;
  top: 1.35rem;
  left: 0.59rem;
  position: absolute;
}
.ailanding .ailading_text {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 4.28rem;
  font-size: 0.53rem;
  letter-spacing: 0.30193237rem;
  line-height: 1;
  font-weight: 200;
}
.ailanding .ailading_btn {
  width: 3.24rem;
  height: 0.8rem;
  position: absolute;
  bottom: 2.63rem;
  left: 0;
  right: 0;
  margin: auto;
  background: #000000;
  color: #ffffff;
  font-size: 0.36rem;
  line-height: 1;
  line-height: 0.8rem;
  text-align: center;
  border-radius: 1.20772947rem;
}
