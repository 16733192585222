.common-groupcourseli {
  min-height: 1.21rem;
  padding: 0.33816425rem 0;
  overflow: hidden;
  border-bottom: 0.01811594rem solid #e8e8e8;
}
.common-groupcourseli .groupcourseli-leftimg {
  width: 3.02rem;
  height: 1.69rem;
  float: left;
  overflow: hidden;
  position: relative;
}
.common-groupcourseli .groupcourseli-leftimg img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.common-groupcourseli .groupcourseli-rightbox {
  width: 5.86rem;
  float: left;
  margin-left: 0.31rem;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-title {
  font-size: 0.36rem;
  line-height: 0.53rem;
  height: 1.09rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist.paddingrightonly {
  padding-right: 1.57rem;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist {
  padding-top: 0.12rem;
  position: relative;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist span {
  display: inline-block;
  border: 0.01811594rem solid #d8d8d8;
  margin-right: 0.24rem;
  padding-left: 0.5rem;
  padding-right: 0.19rem;
  padding-top: 0.07rem;
  padding-bottom: 0.07rem;
  border-radius: 0.30193237rem;
  font-size: 0.24rem;
  color: #929292;
  line-height: 1;
  line-height: 1.2;
  position: relative;
  margin-bottom: 0.12rem;
  word-wrap: break-word;
  word-break: break-all;
  max-width: 3.99rem;
  background: #f9f9f9;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist span.addcourse {
  background-color: #ec6602;
  font-size: 0.24rem;
  color: #ffffff;
  padding: 0.07246377rem 0.36231884rem;
  border: 0.01811594rem solid #ec6602;
  position: absolute;
  bottom: 0rem;
  right: 0rem;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist span.overname {
  background: #ffffff;
  padding-left: 0.19rem;
  padding-right: 0.19rem;
  border: 0.01811594rem solid #ec6602;
  color: #ec6602;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist span.noover {
  padding-left: 0.19rem;
  padding-right: 0.19rem;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist span.icon_people::after {
  content: ' ';
  width: 0.27rem;
  height: 0.27rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0.18rem;
  background: url(../../static/images/group/group-icon-people.png) no-repeat top left / 100% 100%;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist span.icon_time::after {
  content: ' ';
  width: 0.27rem;
  height: 0.27rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0.18rem;
  background: url(../../static/images/group/group-icon-time.png) no-repeat top left / 100% 100%;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist span.icon_play {
  background: none;
  border: none;
}
.common-groupcourseli .groupcourseli-rightbox .groupcourseli-iconlist span.icon_play::after {
  content: ' ';
  width: 0.27rem;
  height: 0.27rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0.18rem;
  background: url(../../static/images/group/group-icon-play.png) no-repeat top left / 100% 100%;
}
