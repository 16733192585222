.shopmallpersonscroll {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  background: #ffffff;
}
.shopmallpersonscroll .person_box {
  width: 100%;
  overflow: hidden;
}
.shopmallpersonscroll .person_box .person_top_line {
  border-bottom: 0.01811594rem solid #c1c1c1;
  border-top: 0.01811594rem solid #c1c1c1;
  height: 0.24rem;
  width: 100%;
  background: #f1f1f1;
}
.shopmallpersonscroll .person_box .person_title {
  padding-top: 0.48rem;
  padding-bottom: 0.87rem;
  padding-left: 0.39rem;
  font-weight: bold;
}
.shopmallpersonscroll .person_box .person_top_info {
  padding: 0 0.38647343rem;
  line-height: 1;
  overflow: hidden;
  position: relative;
  margin-bottom: 0.63rem;
}
.shopmallpersonscroll .person_box .person_top_info .person_left_text {
  width: 6.79rem;
  float: right;
  text-align: right;
  line-height: 0.56rem;
  font-size: 0.36rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 限制在一个块元素显示的文本的行数 */
  -webkit-box-orient: vertical;
  /* 垂直排列 */
  word-break: break-all;
  /* 内容自动换行 */
}
.shopmallpersonscroll .person_box .person_top_info .person_left_title {
  width: 2.42rem;
  height: 0.36rem;
  position: absolute;
  line-height: 1;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 0.36rem;
  left: 0;
  left: 0.36rem;
}
.shopmallpersonscroll .person_box .person_top_info .person_left_title img {
  width: 0.41rem;
  display: inline-block;
  position: relative;
  top: -0.02rem;
  margin-right: 0.36rem;
}
.shopmallpersonscroll .person_box .person_top_name {
  padding: 0 0.38647343rem;
  line-height: 1;
  overflow: hidden;
  position: relative;
  margin-top: 0.87rem;
  margin-bottom: 0.39rem;
}
.shopmallpersonscroll .person_box .person_top_name .person_left_text {
  width: 6.79rem;
  float: right;
  text-align: right;
  line-height: 0.56rem;
  font-size: 0.36rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 限制在一个块元素显示的文本的行数 */
  -webkit-box-orient: vertical;
  /* 垂直排列 */
  word-break: break-all;
  /* 内容自动换行 */
}
.shopmallpersonscroll .person_box .person_top_name .person_left_title {
  width: 2.42rem;
  height: 0.36rem;
  position: absolute;
  line-height: 1;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  left: 0.36rem;
}
.shopmallpersonscroll .shopmallpersonheader {
  width: 100%;
  height: 1.45rem;
  background: #ffffff;
  position: fixed;
  top: 1.21rem;
  left: 0;
  z-index: 2;
  border-bottom: 0.01811594rem solid #dedede;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li.active .shopmallpersonheader_li_img1 {
  background: url(../../static/images/shopmall/shopperson_icon1_active.png) no-repeat center center / 100% 100%;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li.active .shopmallpersonheader_li_img2 {
  background: url(../../static/images/shopmall/shopperson_icon2_active.png) no-repeat center center / 100% 100%;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li.active .shopmallpersonheader_li_img3 {
  background: url(../../static/images/shopmall/shopperson_icon3_active.png) no-repeat center center / 100% 100%;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li.active .shopmallpersonheader_li_img4 {
  background: url(../../static/images/shopmall/shopperson_icon4_active.png) no-repeat center center / 100% 100%;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li.active .shopmallpersonheader_li_text {
  font-size: 0.34rem;
  line-height: 0.68rem;
  color: #ec6602;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li {
  width: 25%;
  width: 33.33%;
  text-align: center;
  height: 100%;
  padding-top: 0.22rem;
  float: left;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li .shopmallpersonheader_li_img {
  width: 0.56rem;
  height: 0.5rem;
  margin: 0 auto;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li .shopmallpersonheader_li_text {
  font-size: 0.34rem;
  line-height: 0.68rem;
  color: #303030;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li .shopmallpersonheader_li_img1 {
  background: url(../../static/images/shopmall/shopperson_icon1.png) no-repeat center center / 100% 100%;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li .shopmallpersonheader_li_img2 {
  background: url(../../static/images/shopmall/shopperson_icon2.png) no-repeat center center / 100% 100%;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li .shopmallpersonheader_li_img3 {
  background: url(../../static/images/shopmall/shopperson_icon3.png) no-repeat center center / 100% 100%;
}
.shopmallpersonscroll .shopmallpersonheader .shopmallpersonheader_li .shopmallpersonheader_li_img4 {
  background: url(../../static/images/shopmall/shopperson_icon4.png) no-repeat center center / 100% 100%;
}
.shopmallpersonscroll .shopmallpersonscrollbox {
  padding-top: 2.66rem;
  padding-bottom: 1.45rem;
}
