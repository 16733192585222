.question-list li {
  width: 100%;
}
.question-list li:first-child {
  position: relative;
  z-index: 1;
}
.question-list .question-title {
  width: 100%;
  height: 1.18rem;
  height: auto;
  background: #f9f9f9;
  padding-left: 0.48rem;
  padding-right: 1.16rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #303030;
  font-size: 0.36rem;
  position: relative;
  padding-top: 0.34rem;
  padding-bottom: 0.34rem;
  line-height: 0.51rem;
}
.question-list .question-title.active {
  color: #ec6602;
}
.question-list .question-title::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.04rem;
  background: #e8e8e8;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0rem;
  left: 0rem;
}
.question-list .question-titlearrow {
  display: block;
  width: 0.36rem;
  height: 0.36rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0.48rem;
  margin: auto;
  -webkit-transition: transform .3s ease;
  transition: transform .3s ease;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: url('../../static/images/downdroparrow.png') no-repeat center center / 96% auto;
}
.question-list .question-titlearrow.active {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.question-list .question-secondlist {
  display: none;
  font-size: 0.34rem;
  color: #686868;
  padding: 0.48rem;
  line-height: 0.54rem;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0 0.06rem 0.15rem rgba(0, 0, 0, 0.04) inset;
  box-shadow: 0 0.06rem 0.15rem rgba(0, 0, 0, 0.04) inset;
}
.question-list .question-secondlist::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e8e8e8;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0.01rem;
  left: 0rem;
}
.question-list .question-secondlist.active {
  display: block;
}
