.showlayer.groupinfo {
  z-index: 1001;
  z-index: 1112;
}
.showlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
}
.showlayer .loginlayer.small {
  height: 2.9rem;
}
.showlayer .loginlayer {
  width: 8.7rem;
  height: 5.19rem;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding-top: 1.09rem;
  overflow: hidden;
}
.showlayer .loginlayer .morecentent_subtn {
  background: #ec6602;
  display: inline-block;
  position: absolute;
  bottom: 0.72463768rem;
  left: 0;
  right: 0;
  width: 2.42rem;
  height: 0.72rem;
  line-height: 0.72rem;
  text-align: center;
  color: #ffffff;
  border-radius: 1.20772947rem;
  margin: auto;
}
.showlayer .loginlayer .morelist_cententcs {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
}
.showlayer .loginlayer .morelist_cententcs .morecentent_title.small {
  padding-top: 0.6rem;
  text-align: center;
}
.showlayer .loginlayer .morelist_cententcs .morecentent_title {
  font-size: 0.36rem;
  line-height: 0.58rem;
  padding-left: 0.58rem;
  padding-right: 0.58rem;
  padding-top: 0.3rem;
  padding-bottom: 0.18rem;
  font-weight: bold;
}
.showlayer .loginlayer .morelist_cententcs .morelist_licslist {
  width: 7.25rem;
  padding-left: 0rem;
  padding-top: 0rem;
  margin: 0 auto;
  border-bottom: 0.01811594rem solid #bfbfbf;
  position: relative;
  min-height: 1.09rem;
  line-height: 1.09rem;
}
.showlayer .loginlayer .morelist_cententcs .morelist_licslist span {
  width: 0.48rem;
  height: 0.48rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 0.03623188rem #cccccc solid;
  border-radius: 1.20772947rem;
}
.showlayer .loginlayer .morelist_cententcs .morelist_licslist span i {
  display: none;
}
.showlayer .loginlayer .morelist_cententcs .morelist_licslist span.hover {
  width: 0.48rem;
  height: 0.48rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 0.03623188rem #ec6602 solid;
  border-radius: 1.20772947rem;
}
.showlayer .loginlayer .morelist_cententcs .morelist_licslist span.hover i {
  width: 0.24rem;
  height: 0.24rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border-radius: 1.20772947rem;
  background: #ec6602;
}
.showlayer .loginlayer .morelist_top {
  position: absolute;
  width: 100%;
  height: 1.09rem;
  top: 0;
  left: 0;
  z-index: 2;
  background: #009999;
}
.showlayer .loginlayer .morelist_top .morelist_title {
  color: #ffffff;
  font-size: 0.39rem;
  font-weight: bold;
  padding-left: 0.48rem;
  line-height: 1.09rem;
}
.showlayer .loginlayer .morelist_top .morelist_close {
  width: 1.09rem;
  height: 1.09rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: url(../../static/images/closevideolayer.png) no-repeat center center / 0.36rem 0.36rem;
}
