.common-searchaddlist {
  width: 100%;
  height: 1.45rem;
  padding: 0.28985507rem 0.36231884rem;
  background: #f9f9f9;
  position: relative;
  padding-left: 0.97rem;
}
.common-searchaddlist .closesearch {
  width: 0.39rem;
  height: 0.39rem;
  background: url(../../static/images/homeplay/closesearch.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  margin: auto;
  left: 0.36rem;
}
.common-searchaddlist .addlist_banner {
  font-size: 0.34rem;
  line-height: 1.45rem;
  height: 1.45rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.common-searchaddlist .addlist_banner a {
  float: left;
  margin: 0 0.60386473rem;
  display: inline-block;
  color: #303030;
  position: relative;
  width: 1.45rem;
}
.common-searchaddlist .addlist_banner .bannner_names {
  position: relative;
  display: block;
  display: inline-block;
  color: #303030;
  text-align: center;
}
.common-searchaddlist .addlist_banner .banner_search {
  width: 0.48rem;
  height: 0.48rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.39rem;
  background: url(../../static/images/homeplay/search_bigicon.png) no-repeat top left / 100% 100%;
}
.common-searchaddlist .addlist_banner .bannner_names.active:after {
  content: ' ';
  height: 0.05rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ec6602;
}
.common-searchaddlist .search-input {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 0.01449275rem solid #e1e1e1;
  border-radius: 1.45rem;
  position: relative;
  padding-left: 0.36rem;
  padding-right: 0.85rem;
  -webkit-box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}
.common-searchaddlist .search-btn {
  display: block;
  width: 0.6rem;
  height: 100%;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0.29rem;
  background: url('../../static/images/commonSearch.png') no-repeat center center / 0.36rem auto;
}
.common-searchaddlist .search-value {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  color: #333333;
  font-size: 0.36rem;
}
