.swiper-container.morheight {
  height: 5rem;
}
.swiper-container.homeback {
  height: 2.42rem;
  height: 3.5rem;
  margin-top: 0.48309179rem;
  overflow: inherit;
  background: #ffffff;
}
.swiper-container.homeback .nagtive-color {
  color: #ec6602;
  font-size: 0.30193237rem;
  font-weight: bold;
  margin-right: 0.12077295rem;
}
.swiper-container.homeback .banner-imgborder {
  width: 100%;
  height: 100%;
  border-radius: 0.48309179rem;
  overflow: hidden;
}
.swiper-container.homeback .swiper-nagtive {
  color: #000000;
  font-size: 0.28985507rem;
  font-weight: bold;
  right: 0.9178744rem;
  bottom: 0.26570048rem;
}
.swiper-container.homeback .swiper-nagtive span.nagtive-color {
  color: #ec6602;
  font-size: 0.30193237rem;
  font-weight: bold;
  margin-right: 0.12077295rem;
}
.swiper-container.homeback .swiper-nagtive span {
  margin: 0;
  line-height: 1;
}
.swiper-container.homeback .swiper-wrapper {
  width: 100%;
  height: 100%;
}
.swiper-container.homeback .swiper-wrapper .swiper-slide {
  background: #ffffff;
}
.swiper-container.homeback .swiper-wrapper .swiper-slide .banner-img {
  padding: 0 0.48309179rem;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.48309179rem;
}
.swiper-container.homeback .swiper-wrapper .swiper-slide .banner-img img {
  box-shadow: 0 0.24154589rem 0.36231884rem rgba(0, 0, 0, 0.2);
  max-height: 100%;
}
.swiper-container.homeback .swiper-wrapper .swiper-slide a {
  padding: 0 0.48309179rem;
}
.swiper-container.homeback .swiper-wrapper .swiper-slide a img {
  box-shadow: 0 0.24154589rem 0.36231884rem rgba(0, 0, 0, 0.2);
  max-height: 100%;
}
.swiper-container.diseaseswiper {
  height: 2.42rem;
  height: 3.48rem;
  margin-top: 0.24154589rem;
  overflow: inherit;
  background: #ffffff;
}
.swiper-container.diseaseswiper .nagtive-color {
  color: #ec6602;
  font-size: 0.30193237rem;
  font-weight: bold;
  margin-right: 0.12077295rem;
}
.swiper-container.diseaseswiper .swiper-nagtive {
  color: #000000;
  font-size: 0.28985507rem;
  font-weight: bold;
  right: 0.9178744rem;
  bottom: 0.26570048rem;
}
.swiper-container.diseaseswiper .swiper-nagtive span.nagtive-color {
  color: #ec6602;
  font-size: 0.30193237rem;
  font-weight: bold;
  margin-right: 0.12077295rem;
}
.swiper-container.diseaseswiper .swiper-nagtive span {
  margin: 0;
  line-height: 1;
}
.swiper-container.diseaseswiper .swiper-wrapper {
  width: 100%;
  height: 100%;
}
.swiper-container.diseaseswiper .swiper-wrapper .swiper-slide {
  background: #ffffff;
}
.swiper-container.diseaseswiper .swiper-wrapper .swiper-slide .banner-img {
  padding: 0 0.48309179rem;
  position: relative;
  width: 100%;
  height: 100%;
}
.swiper-container.diseaseswiper .swiper-wrapper .swiper-slide .banner-img .banner-img-box {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 0.48309179rem;
  box-shadow: 0 0.24154589rem 0.36231884rem rgba(0, 0, 0, 0.2);
}
.swiper-container.diseaseswiper .swiper-wrapper .swiper-slide .banner-img img {
  max-height: 100%;
}
.swiper-container.diseaseswiper .swiper-wrapper .swiper-slide a {
  padding: 0 0.48309179rem;
}
.swiper-container.diseaseswiper .swiper-wrapper .swiper-slide a img {
  box-shadow: 0 0.24154589rem 0.36231884rem rgba(0, 0, 0, 0.2);
  max-height: 100%;
}
.swiper-container {
  width: 100%;
  height: 3.86rem;
  background: #666;
}
.swiper-container .swiper-wrapper {
  width: 100%;
  height: 100%;
}
.swiper-container .swiper-slide {
  font-size: 0.36rem;
  color: #333;
}
.swiper-container .swiper-nagtive {
  position: absolute;
  bottom: 0.39rem;
  right: 0.36rem;
  font-size: 0.29rem;
  z-index: 99;
  color: #ffffff;
  -webkit-text-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
  text-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
}
.swiper-container .swiper-nagtive span {
  margin: 0 0.1rem;
}
.swiper-container .nagtive-color {
  color: #009999;
  font-size: 0.39rem;
}
.swiper-container .banner-text {
  position: absolute;
  bottom: 0.36rem;
  left: 0.39rem;
}
.swiper-container .banner-text h3 {
  font-size: 0.39rem;
  color: #fff;
  -webkit-text-shadow: 0 0 0.01rem rgba(0, 0, 0, 0.1);
  text-shadow: 0 0 0.01rem rgba(0, 0, 0, 0.1);
}
.swiper-container .banner-text p {
  color: #fff;
  font-size: 0.31rem;
  margin-right: 0.12rem;
  -webkit-text-shadow: 0 0 0.01rem rgba(0, 0, 0, 0.1);
  text-shadow: 0 0 0.01rem rgba(0, 0, 0, 0.1);
  margin-top: 0.02rem;
}
.swiper-container .banner-text p span {
  margin-right: 0.12rem;
}
.swiper-container .bannerMaks {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0rem;
  left: 0rem;
  background: rgba(0, 0, 0, 0.16);
  display: none;
}
.swiper-container .bannerStart {
  display: block;
  width: 2.25rem;
  height: 0.85rem;
  position: absolute;
  top: 0.24rem;
  right: 0.29rem;
  background: url('../../static/images/isplaying.png') no-repeat center center / 100% auto;
}
