.common-sysnotice_li {
  font-size: 0.36rem;
  min-height: 100px;
  padding-top: 0.12rem;
  padding-left: 0.72rem;
  padding-right: 0.72rem;
  background: none;
  color: #1a1a1a;
}
.common-sysnotice_li .sysnotice_li_box {
  border-bottom: 0.01811594rem solid #e6e6e6;
  padding-bottom: 0.36rem;
  position: relative;
}
.common-sysnotice_li .sysnotice_li_box .sysnotice_li_close {
  width: 0.25rem;
  height: 0.25rem;
  background: url(../../static/images/notice/closenotice.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0;
  top: 0.42rem;
  right: 0;
}
.common-sysnotice_li .sysnotice_li_box .sysnotice_li_title {
  padding-top: 0.36rem;
  padding-bottom: 0.24rem;
  line-height: 1;
}
.common-sysnotice_li .sysnotice_li_box .sysnotice_li_text {
  font-size: 0.34rem;
  line-height: 0.48rem;
}
.common-sysnotice_li .sysnotice_li_box .sysnotice_li_date {
  font-size: 0.29rem;
  color: #929292;
  line-height: 1;
  padding-top: 0.19rem;
}
