.common-poster_li {
  background: #f6f6f6;
  min-height: 100px;
  border: 0.03623188rem solid #f6f6f6;
}
.common-poster_li .poster_li_committext {
  padding-left: 0.22rem;
  padding-right: 0.22rem;
  padding-top: 0.24rem;
  padding-bottom: 0.36rem;
  font-size: 0.36rem;
  line-height: 1;
}
.common-poster_li .poster_li_content {
  background: #ffffff;
  padding-top: 0.27rem;
  padding-bottom: 0.27rem;
  padding-left: 0.24rem;
  padding-right: 0.24rem;
  overflow: hidden;
}
.common-poster_li .poster_li_content .poster_li_title {
  color: #1a1a1a;
  font-size: 0.39rem;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.common-poster_li .poster_li_content .poster_li_iconlist {
  padding-top: 0.24rem;
  width: 100%;
  overflow: hidden;
}
.common-poster_li .poster_li_content .poster_li_iconlist span {
  float: left;
  position: relative;
  padding-left: 0.51rem;
  color: #929292;
  font-size: 0.34rem;
  line-height: 0.39rem;
  margin-right: 0.48rem;
}
.common-poster_li .poster_li_content .poster_li_iconlist .poster_li_love::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 0.37rem;
  height: 0.35rem;
  background: url(../../static/images/notice/loveicon.png) no-repeat top left / 100% 100%;
}
.common-poster_li .poster_li_content .poster_li_iconlist .poster_li_comment::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 0.37rem;
  height: 0.35rem;
  background: url(../../static/images/notice/comment.png) no-repeat top left / 100% 100%;
}
.common-poster_li .poster_li_content .poster_li_img {
  width: 2.29rem;
  height: 1.59rem;
  overflow: hidden;
  position: relative;
  float: right;
  margin-top: 0.24rem;
}
.common-poster_li .poster_li_content .poster_li_img img {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.common-poster_li .poster_li_content .poster_li_desc {
  color: #666666;
  float: left;
  padding-top: 0.24rem;
  font-size: 0.36rem;
  width: 5.99rem;
  line-height: 0.48rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
