.bbslayerinfo.bbslayer {
  z-index: 1000;
}
.grouplayer_list {
  font-size: 0.34rem;
  line-height: 0.43rem;
  color: #000000;
  padding: 0.33816425rem 0.55555556rem;
  padding-top: 0;
  border-bottom: 0.01811594rem solid #e5e5e5;
  max-height: 2.11352657rem;
  overflow: hidden;
  position: relative;
}
.grouplayer_list .iScrollIndicator {
  width: 0.12077295rem !important;
}
.grouplayer_list .grouplayer_listbox {
  padding-bottom: 0.6rem;
}
.grouplayer_list .grouplayer_listbox .bottom {
  padding-bottom: 0.24rem;
}
.bbslayer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 222;
}
.bbslayer .elast-close {
  display: block;
  width: 0.97rem;
  height: 1.21rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: url('../../static/images/closeIcon.png') no-repeat center center / 60% auto;
}
.bbslayer h3 {
  width: 100%;
  height: 1.21rem;
  background: #009999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #fff;
  font-size: 0.39rem;
  padding-left: 0.48rem;
  position: relative;
}
.bbslayer .Footlayer {
  height: auto;
  width: 8.7rem;
  background: #fff;
  position: absolute;
}
.bbslayer .bbslayer_btnbox {
  width: 100%;
  height: 1.79rem;
  background-color: #ffffff;
  text-align: center;
}
.bbslayer .bbslayer_btnbox .bbslayer_btn {
  display: inline-block;
  background: #ec6602;
  color: #ffffff;
  font-size: 0.36rem;
  line-height: 1;
  padding: 0.36231884rem 1.26811594rem;
  border-radius: 2.41545894rem;
  margin-top: 0.34rem;
}
.bbslayer .bbslayer_textbox.groupinfo {
  min-height: 4.83091787rem;
}
.bbslayer .bbslayer_textbox.groupinfo .grouplayer_list {
  max-height: 4.52898551rem;
}
.bbslayer .bbslayer_textbox {
  background: #ffffff;
  min-height: 1.20772947rem;
}
.bbslayer .bbslayer_textbox .bbslayer_text {
  font-size: 0.36rem;
  line-height: 0.56rem;
  color: #000000;
  padding: 0.33816425rem 0.55555556rem;
  border-bottom: 0.01811594rem solid #e5e5e5;
}
.bbslayer .bbslayer_textbox .bbslayer_img {
  border-bottom: 0.01811594rem solid #e5e5e5;
}
