.personal-header {
  width: 100%;
  height: 2.84rem;
  background: #f1f1f1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.36rem;
  color: #1b1b1b;
  text-align: center;
}
.personal-header .personal-img {
  width: 1.51rem;
  height: 1.51rem;
  display: -webkit-flex;
  display: flex;
  display: block;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: 0.01811594rem solid #bababa;
  margin: 0 auto;
  margin-bottom: 0.24rem;
}
.personal-header span.text {
  display: block;
  padding: 0 1.20772947rem;
}
