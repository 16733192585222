.elasticframesurver {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  left: 0rem;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
.elasticframesurver .addnewsigntext {
  padding: 0.48rem;
  background: #fff;
  font-size: 0.36rem;
  color: #333333;
  padding: 1.08rem 0.48rem;
  width: 100%;
  text-align: center;
}
.elasticframesurver .addnewsigntext .addnewsigntext_span {
  display: block;
  width: 100%;
  color: #de6c2b;
}
.elasticframesurver .addnewsigntext p {
  display: block;
  width: 100%;
  font-size: 0.43rem;
}
.elasticframesurver .elastcenter {
  width: 8.7rem;
  background: #fff;
  position: relative;
}
.elasticframesurver h3 {
  width: 100%;
  height: 1.21rem;
  background: #009999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #fff;
  font-size: 0.39rem;
  padding-left: 0.48rem;
  position: relative;
}
.elasticframesurver .elast-close {
  display: block;
  width: 0.97rem;
  height: 1.21rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: url('../../static/images/closeIcon.png') no-repeat center center / 60% auto;
}
.elasticframesurver .elast-text.morebtn {
  width: 100%;
  height: 0.36rem;
}
.elasticframesurver .elast-text.morebtn p {
  background: #ed6a09;
  color: #ffffff;
  border-radius: 0.48309179rem;
  padding: 0.24154589rem 0.36231884rem;
}
.elasticframesurver .elast-text.none {
  display: none;
}
.elasticframesurver .elast-text {
  padding: 0.48rem;
  min-height: 3.38rem;
  background: #fff;
  font-size: 0.36rem;
  color: #333333;
}
.elasticframesurver .elast-text p {
  text-align: center;
}
.elasticframesurver .elast-btnlist {
  text-align: center;
}
.elasticframesurver .elast-btnlist span {
  display: inline-block;
  margin-right: 0.66rem;
  width: 1.93rem;
  padding-top: 0.12rem;
  padding-bottom: 0.12rem;
  text-align: center;
  margin-top: 0.67rem;
  color: #fff;
  border-radius: 1.21rem;
  background: #ee662b;
}
