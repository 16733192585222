.elasticframe {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  left: 0rem;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.elasticframe .common-masktextplay {
  padding: 0.60386473rem 0;
  text-align: center;
  font-size: 0.39rem;
}
.elasticframe .common-masktextplay .masktextplaytitle {
  line-height: 0.6rem;
}
.elasticframe .common-masktextplay .goplay {
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.12rem;
}
.elasticframe .common-masktextplay .goplay .goreg {
  border: 0;
  display: inline-block;
  line-height: 1;
  padding: 10px 20px;
  margin-top: 10px;
  color: #fff;
  background: #ee662b;
  border-radius: 100px;
  cursor: pointer;
  margin-right: 0.72rem;
}
.elasticframe .common-masktextplay .goplay .goreglogin {
  border: 0;
  display: inline-block;
  line-height: 1;
  padding: 10px 20px;
  margin-top: 10px;
  color: #fff;
  background: #ee662b;
  border-radius: 100px;
  cursor: pointer;
}
.elasticframe h3 {
  width: 100%;
  height: 1.21rem;
  background: #009999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #fff;
  font-size: 0.39rem;
  padding-left: 0.48rem;
  position: relative;
}
.elasticframe .elast-close {
  display: block;
  width: 0.97rem;
  height: 1.21rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: url('../../static/images/closeIcon.png') no-repeat center center / 60% auto;
}
