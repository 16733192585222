.ask-scroll {
  width: 100%;
  position: absolute;
  top: 4.25rem;
  left: 0rem;
  bottom: 0rem;
  padding-top: 1.16rem;
  padding-bottom: 1.21rem;
  background: #fff;
  z-index: 9;
  -webkit-transition: transform .3s ease;
  transition: transform .3s ease;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.ask-scroll.active {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}
.ask-title {
  width: 100%;
  height: 1.16rem;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.36rem;
  color: #333333;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 0.43rem;
  -webkit-box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
}
.ask-title::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e3e3e3;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
}
.askarrow {
  display: block;
  width: 1.35rem;
  height: 100%;
  position: absolute;
  right: 0.24rem;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url('../../static/images/detailsarrow_two.png') no-repeat center center / 25% 0.19323671rem;
}
.ask-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-left: 0.48rem;
  padding-right: 0.48rem;
}
.ask-li {
  padding-left: 1.01rem;
  position: relative;
  padding-top: 0.43rem;
}
.ask-img {
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  top: 0.43rem;
  left: 0rem;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.ask-name {
  font-size: 0.34rem;
  color: #1a1a1a;
}
.ask-textcontain {
  padding-bottom: 0.29rem;
  position: relative;
}
.ask-textcontain::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e3e3e3;
  position: absolute;
  bottom: 0;
  left: 0;
}
.ask-talk {
  font-size: 0.34rem;
  color: #444444;
  line-height: 0.42rem;
  margin-top: 0.22rem;
}
.ask-time {
  font-size: 0.29rem;
  color: #999999;
  margin-top: 0.19rem;
}
.ask-li.active .ask-name {
  color: #069b9b;
}
.ask-li.active .ask-img {
  border: 0.01811594rem solid #069b9b;
}
.ask-answer {
  background: #f8f8f8;
  border: 0.01811594rem solid #e3e3e3;
  padding: 0.24rem;
  margin-top: 0.12rem;
}
.ask-answer h4 {
  font-size: 0.34rem;
  color: #fe5b00;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}
.ask-answer h4 span {
  color: #999999;
  position: absolute;
  right: 0;
}
.ask-answer strong {
  color: #069b9b;
}
.ask-answer p {
  font-size: 0.34rem;
  color: #303030;
  line-height: 0.48rem;
  margin-top: 0.24rem;
}
