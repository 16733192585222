.online-scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #f9f9f9;
  font-size: 0.36rem;
  color: #444444;
}
.online-scroll .iscroll-padding {
  padding-top: 0.24rem;
}
.readonly.online-scroll .online-submit {
  display: none;
}
.readonly.online-scroll .iscroll-padding {
  padding-bottom: 0rem;
}
.onlinebox {
  padding: 0.18115942rem 0.36231884rem;
  position: relative;
}
.online-img {
  display: block;
  width: 0.97rem;
  height: 0.97rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
  border-radius: 0.07rem;
  overflow: hidden;
  position: absolute;
  top: 0.18rem;
  background: #fff;
}
.online-img.teach-imgs {
  border: 0.02415459rem solid #fe5b00;
}
.online-img.teach-imgs::after {
  content: '';
  display: block;
  width: 0.3rem;
  height: 0.24rem;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url('../../static/images/img-face.png') no-repeat center center / 100% 100%;
}
.online-name {
  display: block;
  width: 100%;
  font-size: 0.29rem;
  color: #98abb8;
  line-height: 0.31rem;
  padding-bottom: 0.1rem;
}
.online-other {
  max-width: 100%;
  display: inline-block;
  padding: 0.24rem;
  background: #fff;
  border-radius: 0.12rem;
  border: 0.01811594rem solid #d0dbe3;
  position: relative;
}
.online-other::after {
  content: '';
  display: block;
  width: 0.19rem;
  height: 0.27rem;
  position: absolute;
  top: 0.36rem;
  left: -0.18rem;
  background: url('../../static/images/onlinearrow_01.png') no-repeat center center / 100% auto;
}
.online-teach {
  display: inline-block;
  padding: 0.24rem;
  background: #fe5b00;
  border-radius: 0.12rem;
  color: #fff;
  position: relative;
}
.online-teach::after {
  content: '';
  display: block;
  width: 0.19rem;
  height: 0.29rem;
  position: absolute;
  top: 0.36rem;
  left: -0.17rem;
  background: url('../../static/images/onlinearrow_02.png') no-repeat center center / 100% auto;
}
.online-my {
  max-width: 100%;
  display: inline-block;
  padding: 0.24rem;
  background: #0e9e9e;
  border-radius: 0.12rem;
  color: #fff;
  position: relative;
}
.online-my::after {
  content: '';
  display: block;
  width: 0.21rem;
  height: 0.28rem;
  position: absolute;
  top: 0.36rem;
  right: -0.17rem;
  background: url('../../static/images/onlinearrow_03.png') no-repeat center center / 100% auto;
}
.teach-img {
  border: 0.02rem solid #fe5b00;
}
.teach-img::after {
  content: '';
  display: block;
  width: 0.3rem;
  height: 0.24rem;
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  background: url('../../static/images/img-face.png') no-repeat;
}
.style-right {
  padding-right: 1.69rem;
  text-align: right;
}
.style-right .online-img {
  right: 0.36rem;
}
.style-right .online-name {
  text-align: right;
}
.style-left {
  padding-left: 1.69rem;
}
.style-left .online-img {
  left: 0.36rem;
}
.online-time {
  text-align: center;
  padding: 0.18rem;
}
.online-time span {
  display: inline-block;
  font-size: 0.27rem;
  color: #fff;
  padding: 0.12077295rem 0.24154589rem;
  background: #e3e3e3;
  border-radius: 0.12rem;
}
.online-submit {
  width: 100%;
  height: 1.21rem;
  background: #fff;
  border-top: 0.01811594rem solid #e3e3e3;
  -webkit-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 0.24rem;
}
.online-submit .online-input {
  width: 8.07rem;
  height: 0.79rem;
  background: #f9f9f9;
  border: 0.01811594rem solid #e3e3e3;
  color: #333333;
  font-size: 0.36rem;
  padding-left: 0.19rem;
  -webkit-appearance: none;
  border-radius: 0;
}
.online-submit .submit-talk {
  width: 1.21rem;
  height: 0.79rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: absolute;
  right: 0.24rem;
  background: #fe5b00;
  color: #fff;
  font-size: 0.36rem;
}
.online-submit .submit-talk.active {
  opacity: 0.8;
}
