.elast-cslive {
  padding: 0.48rem;
}
.elast-cslive .common-cslive-fir {
  font-size: 0.39rem;
}
.elast-cslive .common-cslive-fir p {
  font-size: 0.34rem;
  color: #666;
  display: none;
}
.elast-cslive .common-cslive-input {
  border: 0.01811594rem #cccccc solid;
  width: 7.73rem;
  margin-top: 0.31rem;
  margin-bottom: 0.31rem;
  padding-left: 0.36rem;
  padding-right: 0.36rem;
  height: 0.92rem;
  line-height: 0.92rem;
  color: #000;
  font-size: 0.43rem;
}
.elast-cslive .common-cslive-link {
  color: #1890ff;
}
.gobuyservies {
  position: relative;
  color: #009999;
  font-size: 0.24rem;
  font-size: 0.31rem;
  z-index: 99;
  left: 0;
  right: 0;
  margin: auto;
  line-height: 0.71rem;
  color: #ffffff;
  padding-bottom: 0.48rem;
  overflow: hidden;
  padding-bottom: 0rem;
  height: 0.97rem;
  line-height: 0.97rem;
  text-align: center;
  width: 4.23rem;
}
.gobuyservies span::after {
  content: ' ';
  width: 0.56rem;
  height: 0.56rem;
  background: url(../../static/images/buycourse_icon.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0.07rem;
}
.gobuyservies span {
  position: relative;
  border-radius: 1.21rem;
  background: #ef6502;
  display: inline-block;
  padding-left: 0.76rem;
  padding-right: 0.18rem;
  text-align: center;
  width: 4.23rem;
}
.firsubtext {
  margin: 0 auto;
  background-color: #41999a;
  color: #fff;
  padding: 0.24rem;
  padding: 0 0.24154589rem;
  width: 3.54rem;
  border-radius: 1.21rem;
  margin-bottom: 0.24rem;
  height: 0.97rem;
  line-height: 0.97rem;
  text-align: center;
  width: 4.23rem;
  font-size: 0.39rem;
}
.common-cslive-error {
  color: #ff0000;
  margin-top: -0.07rem;
  margin-bottom: 0.14rem;
}
.textbox-main {
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin: 0.46rem;
}
.textbox-main .textbox-textlist {
  display: block;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
.textbox-main .textbox-textlist:nth-child(1) {
  font-weight: bold;
  border-bottom: 0.01811594rem rgba(0, 0, 0, 0.08) solid;
  padding-bottom: 0.27rem;
  margin-bottom: 0.27rem;
}
.textbox-series {
  margin-top: 0.48rem;
  margin-right: 0.48rem;
  margin-bottom: 0.48rem;
  margin-left: 0.48rem;
}
.textbox-series .noselect {
  text-align: left;
  display: inline;
  padding: 0;
  font-weight: bold;
}
.textbox-series .common-changelist {
  margin-top: 0.19rem;
}
.textbox-series .changelist-changebox {
  padding-top: 0.1rem;
  padding-right: 0.22rem;
  padding-bottom: 0.1rem;
  padding-left: 0.22rem;
  border-radius: 0.1rem;
  margin-top: 0.14rem;
  margin-right: 0.14rem;
  margin-bottom: 0.14rem;
}
.textbox-title {
  padding: 0.48rem;
  min-height: 2.42rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textbox-title b {
  color: #3f9798;
  display: contents;
}
.changelist-changebox {
  border: 0.01811594rem #cccccc solid;
  display: inline-block;
}
.changelist-changebox.active {
  border: 0.01811594rem #cccccc solid;
  background: #009999;
  color: #ffffff;
  display: inline-block;
}
.elasticframe-cslive {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  left: 0rem;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
.elasticframe-cslive .addnewsigntext {
  padding: 0.48rem;
  background: #fff;
  font-size: 0.36rem;
  color: #333333;
  padding: 1.08rem 0.48rem;
  width: 100%;
  text-align: center;
}
.elasticframe-cslive .addnewsigntext .addnewsigntext_span {
  display: block;
  width: 100%;
  color: #de6c2b;
}
.elasticframe-cslive .addnewsigntext p {
  display: block;
  width: 100%;
  font-size: 0.43rem;
}
.elasticframe-cslive .elastcenter {
  width: 8.7rem;
  background: #fff;
  position: relative;
  padding-bottom: 0.6rem;
}
.elasticframe-cslive h3 {
  width: 100%;
  height: 1.21rem;
  background: #009999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #fff;
  font-size: 0.39rem;
  padding-left: 0.48rem;
  position: relative;
}
.elasticframe-cslive .elast-close {
  display: block;
  width: 0.97rem;
  height: 1.21rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: url('../../static/images/closeIcon.png') no-repeat center center / 60% auto;
}
.elasticframe-cslive .elast-text.morebtn {
  width: 100%;
  height: 0.36rem;
}
.elasticframe-cslive .elast-text.morebtn p {
  background: #ed6a09;
  color: #ffffff;
  border-radius: 0.48309179rem;
  padding: 0.24154589rem 0.36231884rem;
}
.elasticframe-cslive .elast-text.none {
  display: none;
}
.elasticframe-cslive .elast-text {
  padding: 0.48rem;
  min-height: 3.38rem;
  background: #fff;
  font-size: 0.36rem;
  color: #333333;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  display: none;
}
