.newtipslayer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  color: #ffffff;
}
.newtipslayer .strp1_tips1 {
  width: 2.42rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 0rem;
  top: 1.69rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
}
.newtipslayer .strp1_tips1 span {
  width: 0.18rem;
  height: 0.36rem;
  background: url(../../static/images/fonticon.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0.24rem;
  left: -0.16rem;
}
.newtipslayer .strp1_tips1 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips1 {
  width: 3.62rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 0rem;
  top: 3.14rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
  transform-origin: right bottom;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips1 span {
  width: 0.36rem;
  height: 0.18rem;
  background: url(../../static/images/fonticon_up.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: -0.16rem;
  left: 0.24rem;
}
.newtipslayer .strp1_tips1 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips2 {
  width: 3.62rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 1.21rem;
  top: 0.24rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
  transform-origin: right bottom;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips2 span {
  width: 0.18rem;
  height: 0.36rem;
  background: url(../../static/images/fonticon_right.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0.24rem;
  right: -0.16rem;
}
.newtipslayer .strp1_tips2 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips3 {
  width: 4.23rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 1.21rem;
  top: 4.59rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
  transform-origin: right bottom;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips3 span {
  width: 0.36rem;
  height: 0.18rem;
  background: url(../../static/images/fonticon_up.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: -0.16rem;
  right: 0.24rem;
}
.newtipslayer .strp1_tips3 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips4 {
  width: 4.23rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 1.21rem;
  top: 7.04rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
  transform-origin: right bottom;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips4 span {
  width: 0.36rem;
  height: 0.18rem;
  background: url(../../static/images/fonticon_up.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: -0.16rem;
  right: 0.24rem;
}
.newtipslayer .strp1_tips4 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips5 {
  width: 4.23rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 1.21rem;
  top: 8.29rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
  transform-origin: right bottom;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips5 span {
  width: 0.36rem;
  height: 0.18rem;
  background: url(../../static/images/fonticon_up.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: -0.16rem;
  right: 0.24rem;
}
.newtipslayer .strp1_tips5 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips6.nooadminexam {
  top: 7.13rem;
}
.newtipslayer .strp1_tips6.nooadminexama {
  top: 9.54rem;
}
.newtipslayer .strp1_tips6.noadmin {
  top: 9.54rem;
}
.newtipslayer .strp1_tips6 {
  width: 4.23rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 1.21rem;
  top: 11.98rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
  transform-origin: right bottom;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips6 span {
  width: 0.36rem;
  height: 0.18rem;
  background: url(../../static/images/fonticon_up.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: -0.16rem;
  right: 0.24rem;
}
.newtipslayer .strp1_tips6 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips7 {
  width: 4.23rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 1.81rem;
  top: 5.19rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
  transform-origin: right bottom;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips7 span {
  width: 0.36rem;
  height: 0.18rem;
  background: url(../../static/images/fonticon_up.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: -0.16rem;
  right: 0.24rem;
}
.newtipslayer .strp1_tips7 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips8 {
  width: 4.23rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 1.81rem;
  top: 2.66rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
  transform-origin: right bottom;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips8 span {
  width: 0.36rem;
  height: 0.18rem;
  background: url(../../static/images/fonticon_up.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: -0.16rem;
  right: 0.24rem;
}
.newtipslayer .strp1_tips8 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips9 {
  width: 4.23rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 1.69rem;
  top: 0.12rem;
  cursor: pointer;
  border-radius: 0.18115942rem;
  transform-origin: right bottom;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips9 span {
  width: 0.18rem;
  height: 0.36rem;
  background: url(../../static/images/fonticon_right.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0.24rem;
  right: -0.16rem;
}
.newtipslayer .strp1_tips9 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips10 {
  width: 7.73rem;
  height: 5.92rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 0rem;
  left: 0rem;
  top: 0rem;
  bottom: 0rem;
  margin: auto;
  cursor: pointer;
  border-radius: 0.18115942rem;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips10 .strp1_tips10_text {
  padding: 0rem 0.36231884rem;
  text-align: center;
}
.newtipslayer .strp1_tips10 .strp1_tips10_img {
  padding: 0.36231884rem;
}
.newtipslayer .strp1_tips10 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
.newtipslayer .strp1_tips11 {
  width: 7.73rem;
  height: 9.54rem;
  background: #ec6602;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  padding: 0.18115942rem;
  font-size: 0.36rem;
  right: 0rem;
  left: 0rem;
  top: 0rem;
  bottom: 0rem;
  margin: auto;
  cursor: pointer;
  border-radius: 0.18115942rem;
  animation: scaleAnimation 1.5s infinite linear;
}
.newtipslayer .strp1_tips11 .strp1_tips11_text {
  padding: 0rem 0.36231884rem;
  text-align: center;
}
.newtipslayer .strp1_tips11 .strp1_tips11_img {
  padding: 0.36231884rem;
}
.newtipslayer .strp1_tips11 p {
  text-align: right;
  padding: 0;
  padding-top: 0.12rem;
  margin: 0;
  font-size: 0.27rem;
  cursor: pointer;
}
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
