.common-backmelist {
  min-height: 100px;
  padding-top: 0.12rem;
  padding-left: 0.36rem;
  padding-right: 0.36rem;
}
.common-backmelist .backmelist_notice {
  padding-top: 0.39rem;
  padding-bottom: 0.36rem;
  border-bottom: 0.01811594rem solid #e6e6e6;
}
.common-backmelist .backmelist_notice .backmelist_text {
  color: #1a1a1a;
  font-size: 0.36rem;
  line-height: 0.48rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.common-backmelist .backmelist_notice .backmelist_topbox {
  height: 0.87rem;
  width: 100%;
  padding-left: 1.04rem;
  position: relative;
}
.common-backmelist .backmelist_notice .backmelist_topbox .backmelist_closethis {
  width: 0.25rem;
  height: 0.25rem;
  background: url(../../static/images/notice/closenotice.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: 0.14rem;
  right: 0.07rem;
}
.common-backmelist .backmelist_notice .backmelist_topbox .backmelist_back.hover {
  background: #ec6602;
  border: 0.01811594rem #ec6602 solid;
  color: #ffffff;
}
.common-backmelist .backmelist_notice .backmelist_topbox .backmelist_back {
  font-size: 0.31rem;
  color: #929292;
  line-height: 1;
  padding: 0.12077295rem 0.30193237rem;
  position: absolute;
  top: -0.02rem;
  right: 0.6rem;
  border: 0.01811594rem #e6e6e6 solid;
  border-radius: 1.21rem;
}
.common-backmelist .backmelist_notice .backmelist_topbox .backmelist_headimg {
  width: 0.87rem;
  height: 0.87rem;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.common-backmelist .backmelist_notice .backmelist_topbox .backmelist_date {
  color: #929292;
  font-size: 0.29rem;
  line-height: 1;
  padding-top: 0.14rem;
}
.common-backmelist .backmelist_notice .backmelist_topbox .backmelist_nicknamestate {
  line-height: 1;
  font-size: 0.34rem;
  color: #000;
  padding-top: 0.06rem;
  overflow: hidden;
}
.common-backmelist .backmelist_notice .backmelist_topbox .backmelist_nicknamestate .backmelist_nickname {
  max-width: 3.38rem;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  float: left;
  line-height: 0.39rem;
}
