.common-header {
  width: 100%;
  height: 1.21rem;
  position: absolute;
  top: 0;
  left: 0;
}
.layerout_layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 222226;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}
.layerout_layer .layerout_layercenter {
  width: 8.7rem;
  height: 4.83rem;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.layerout_layer .layerout_layercenter .layerout_btnlist {
  text-align: center;
  padding-top: 1.21rem;
}
.layerout_layer .layerout_layercenter .layerout_btnlist span:first-child {
  margin-right: 0.6rem;
}
.layerout_layer .layerout_layercenter .layerout_btnlist span {
  width: 1.69rem;
  display: inline-block;
  font-size: 0.39rem;
  background: #3f9798;
  color: #ffffff;
  line-height: 0.97rem;
  text-align: center;
}
.layerout_layer .layerout_layercenter h3 {
  width: 100%;
  height: 1.21rem;
  background: #009999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #fff;
  font-size: 0.39rem;
  padding-left: 0.48rem;
  position: relative;
}
.header-back {
  width: 100%;
  height: 1.21rem;
  background: #ffffff;
  position: relative;
  z-index: 9;
  -webkit-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.05);
}
.header-back .addnewstop.zhibo {
  left: 2.84rem;
}
.header-back .addnewstop.doc {
  left: 4.41rem;
}
.header-back .addnewstop.people {
  left: 5.98rem;
}
.header-back .footer-contain {
  font-size: 0.24rem;
  color: #8c8c8c;
}
.header-back .footer-icon {
  display: block;
  width: 100%;
  height: 0.48rem;
  margin-bottom: 0.12rem;
}
.header-back .doc .footer-icon {
  background: url('../../static/images/footerIcon_02.png') no-repeat center center / auto 100%;
}
.header-back .zhibo .footer-icon {
  background: url('../../static/images/footerIcon_03.png') no-repeat center center / auto 100%;
}
.header-back .people .footer-icon {
  background: url('../../static/images/footerIcon_04.png') no-repeat center center / auto 100%;
}
.header-back .doc .footer-icon.active {
  background: url('../../static/images/footerIcon_02_active.png') no-repeat center center / auto 100%;
}
.header-back .zhibo .footer-icon.active {
  background: url('../../static/images/footerIcon_03_active.png') no-repeat center center / auto 100%;
}
.header-back .people .footer-icon.active {
  background: url('../../static/images/footerIcon_04_active.png') no-repeat center center / auto 100%;
}
.header-back .addnewstop {
  width: 1.45rem;
  height: 100%;
  font-size: 0.24rem;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.header-back .addnewstop a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
.header-back .auto-return {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 2.61rem;
  margin: auto;
  background: url('../../static/images/returnIcon.png') no-repeat center center / 100% 100%;
}
.header-back .auto-return.active {
  background: url('../../static/images/returnIcon_active.png') no-repeat center center / 100% 100%;
}
.header-back::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #d9d9d9;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
}
.common-header-logo {
  width: 2.15rem;
  width: 2.38rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.36rem;
  margin: auto;
}
.isplaybacktext {
  width: 2.66rem;
  height: 0.39rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3rem;
  font-size: 0.39rem;
  font-weight: bold;
  color: #ec6602;
  line-height: 1;
  margin: auto;
}
.common-select {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0.43rem;
  margin: auto;
  background: url('../../static/images/selectIcon.png') no-repeat center center / 100% auto;
}
.common-select.active {
  opacity: 0.8;
}
.selectMask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 7;
}
.select-list {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 1.21rem;
  left: 0rem;
  bottom: 0;
  z-index: 8;
  padding: 0.36231884rem 0.36231884rem 0.60386473rem;
}
.select-title {
  font-size: 0.34rem;
  line-height: 0.36rem;
  color: #34a2ba;
}
.label-list {
  overflow: hidden;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
}
.label-list li {
  height: 0.72rem;
  line-height: 0.72rem;
  padding: 0 0.24154589rem;
  display: inline-block;
  border: 0.01207729rem solid #e0e0e0;
  border-radius: 0.72rem;
  color: #303030;
  font-size: 0.34rem;
  margin-right: 0.22rem;
  margin-top: 0.22rem;
}
.label-list li.active {
  border-color: #fe5b00;
  background: #fe5b00;
  color: #fff;
}
.select-arrow {
  display: block;
  width: 0.39rem;
  height: 0.19rem;
  background: url('../../static/images/labelArrow.png') no-repeat center center / 100% auto;
  position: absolute;
  right: 0.53rem;
  bottom: -0.17rem;
  z-index: 2;
}
