.common-headergroup {
  width: 100%;
  height: 1.21rem;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  border-bottom: 0.01811594rem #e3e3e3 solid;
  background: #ffffff;
  -webkit-box-shadow: 0 0 0.18115942rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0.18115942rem rgba(0, 0, 0, 0.1);
}
.common-headergroup .headergroup-back {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0.39rem;
  width: 0.63rem;
  height: 0.63rem;
  background: url(../../static/images/group/header-back.png) no-repeat top left / 100% 100%;
}
.common-headergroup .headergroup-title {
  width: 100%;
  text-align: center;
  line-height: 1.21rem;
  font-size: 0.39rem;
  font-weight: 500;
  color: #333333;
}
