.petzonebanner {
  width: 100%;
  margin-bottom: 0.36231884rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.backgroupwriter {
  background: #ffffff;
}
.userlogos.addinfo {
  font-size: 0.46rem;
  color: #ffffff;
  background: #ccc;
}
.addiconlist .homepageiconlist {
  padding-top: 0.66rem;
}
.addiconlist .homepageiconlist .homepageiconli {
  width: 20%;
  display: inline-block;
  padding-bottom: 0.63rem;
}
.addiconlist .homepageiconlist .homepageiconli .homepageiconli_icon_disease {
  width: 0.87rem;
  margin: 0 auto;
}
.addiconlist .homepageiconlist .homepageiconli .homepageiconli_icon {
  width: 0.69rem;
  margin: 0 auto;
}
.addiconlist .homepageiconlist .homepageiconli .homepageiconli_title {
  text-align: center;
  font-size: 0.29rem;
  color: #999999;
  color: #333233;
  line-height: 1;
  padding-top: 0.36rem;
}
.addpetzonetipsbox .typedown-list .typedown-list:after {
  display: none;
}
.addpetzonetipsbox .typedown-list .typedown-list {
  padding: 0;
  margin: 0.36231884rem;
}
.addpetzonetipsbox .typedown-list .typedown-list .typedown-li::after {
  display: none;
}
.addpetzonetipsbox .typedown-list .typedown-list .typedown-li {
  padding: 0.2173913rem 0.24154589rem;
  border: 0.01811594rem solid #e9e9e9;
  background: #ffffff;
  margin-bottom: 0.26570048rem;
  border-radius: 0.24154589rem;
  box-shadow: 0 0 0.38647343rem rgba(0, 0, 0, 0.1);
  line-height: 0.38647343rem;
}
.addpetzonetipsbox .typedown-list .typedown-list .typedown-li .addpetzonetipsbox-tipsicon {
  width: 1.63043478rem;
  height: 0.64009662rem;
  background: url(../../static/images/petzone/petzone_tips.png) no-repeat top left / 100% 100%;
  position: absolute;
  top: -0.24154589rem;
  right: 0.24154589rem;
}
.addpetzonetipsbox .typedown-list .typedown-list .typedown-li .addpetzonetipsbox-img {
  margin-right: 0.50724638rem;
}
.addpetzonetipsbox .typedown-list .typedown-list .typedown-li .addpetzonetipsbox-img img {
  width: 3.24879227rem;
}
.addpetzonetipsbox .typedown-list .typedown-list .typedown-li .typePreview {
  top: inherit;
  bottom: 0.36231884rem;
  border-color: #000000;
  border: 0.01811594rem solid #000000;
}
