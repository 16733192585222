.coursecontentmedical-scroll {
  width: 100%;
  height: 100%;
  background: #f9f9f9;
}
.coursecontentmedical-scroll .course-value {
  padding-bottom: 2.41545894rem;
}
.coursecontentmedical-scroll .iscroll-padding {
  padding-left: 0.36rem;
  padding-right: 0.36rem;
  padding-bottom: 0;
  padding-bottom: 0.72rem;
}
.coursecontentmedical-scroll .coursecontent-title {
  padding-top: 0.31rem;
  padding-bottom: 0.24rem;
  color: #41999a;
}
.coursecontentmedical-scroll .coursecontent-textbox {
  white-space: pre-wrap;
  line-height: 0.51rem;
  font-size: 0.34rem;
  color: #333;
  padding: 0 0.48309179rem;
  padding-top: 0.48rem;
}
