.personal-headernew {
  width: 100%;
  height: 2.84rem;
  background: #f1f1f1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.36rem;
  color: #1b1b1b;
  text-align: center;
}
.personal-headernew .personal-img {
  display: block;
  width: 1.51rem;
  height: 1.51rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: 0.01811594rem solid #bababa;
  margin-bottom: 0.24rem;
}
.headimgnew_box {
  width: 100%;
  height: 2.84rem;
  background: #f1f1f1;
  position: relative;
}
.headimgnew_box .headimg {
  width: 1.76rem;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  top: 0.59rem;
  left: 0.72rem;
}
.headimgnew_box .middle-box {
  top: 0.85rem;
  left: 2.78rem;
  position: absolute;
}
.headimgnew_box .middle-box .nickname {
  color: #1b1b1b;
  font-size: 0.41rem;
  font-weight: bold;
  line-height: 1;
}
.headimgnew_box .middle-box .nickname span {
  margin-left: 0.12rem;
  font-size: 0.27rem;
  color: #999;
}
.headimgnew_box .middle-box .manage-btn {
  color: #ffffff;
  font-size: 0.27rem;
  line-height: 1;
  padding: 0.16908213rem 0.24154589rem;
  background: #ec6500;
  border-radius: 0.36231884rem;
  margin-top: 0.18rem;
}
.headimgnew_box .middle-box .manage-btn a {
  color: #fff;
}
.headimgnew_box .manage-names-sign {
  position: absolute;
  top: 0;
  right: 0;
  right: 0.6rem;
  top: 0.43rem;
  top: 1.3rem;
  text-align: right;
}
.headimgnew_box .manage-names-sign .manage-names-sign-text {
  font-size: 0.22rem;
  color: #686868;
  line-height: 1;
  padding-top: 0.1rem;
}
.headimgnew_box .manage-names-sign .manage-names-sign-btn.active {
  background: #ccc;
}
.headimgnew_box .manage-names-sign .manage-names-sign-btn {
  line-height: 1;
  color: #ffffff;
  font-size: 0.29rem;
  background: #ec6500;
  padding: 0.09661836rem 0.36231884rem;
  display: inline-block;
  border-radius: 0.2173913rem;
}
.headimgnew_box .manage-names-lvbox .manage-names-lvbox-title {
  font-size: 0.31rem;
  color: #ec6500;
  line-height: 1;
  padding-top: 0.29rem;
}
.headimgnew_box .manage-names-lvbox .manage-names-lvbox-title .manage-names-lvbox-font {
  font-style: italic;
  font-size: 0.31rem;
  color: #ec6500;
}
.headimgnew_box .manage-names-lvbox .manage-names-lvbox-title span {
  font-size: 0.29rem;
  color: #686868;
}
.headimgnew_box .manage-names-lvbox .manage-names-lvbox-title span.more_news {
  width: 0.31rem;
  height: 0.31rem;
  display: inline-block;
  position: absolute;
  bottom: 0rem;
  margin-left: 0rem;
  left: 3.03rem;
  padding-top: 0.24rem;
  background: url(../../static/images/mall/28.png) no-repeat top left / 100% 100%;
}
.headimgnew_box .manage-names-lvbox .manage-names-lvbox-title span.more_news .more_new_iconlist {
  background: url(../../static/images/mall/ts_layerbox.png) no-repeat top left / 100% 100%;
  position: absolute;
  bottom: -0.3rem;
  z-index: 999999;
  left: 0.36rem;
  padding-left: 0.36rem;
  line-height: 0.39rem;
  padding-top: 0.12rem;
  padding-bottom: 0.12rem;
  width: 3.62rem;
  color: #449c9d;
}
.headimgnew_box .manage-names-lvbox .manage-names-live {
  width: 100%;
  font-size: 0;
  line-height: 0;
  padding-top: 0.18rem;
}
.headimgnew_box .manage-names-lvbox .manage-names-live span {
  width: 2.78rem;
  height: 0.14rem;
  background: none;
  border-radius: 0.09661836rem;
  border: 0.01811594rem #ec6500 solid;
  display: inline-block;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.headimgnew_box .manage-names-lvbox .manage-names-live span i {
  width: 1.21rem;
  height: 0.12rem;
  background: #ec6500;
  position: relative;
  top: -0.01rem;
  display: inline-block;
  border-radius: 0 0.09661836rem 0.09661836rem 0.09661836rem;
}
.headimgnew_box .right-box {
  font-size: 0.36rem;
  color: #686868;
  top: 1.39rem;
  top: 2.04rem;
  right: 0.72rem;
  line-height: 1;
  position: absolute;
}
.headimgnew_box .right-box .right-box-icon {
  width: 0.34rem;
  height: 0.34rem;
  float: left;
  margin-right: 0.24rem;
}
