.eshop-contentnew {
  padding: 0;
  background: none;
  background: #f1f1f1;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 1.21rem;
}
.eshop-contentnew .contentscroll {
  width: 100%;
  height: 100%;
  padding-top: 1.21rem;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
}
.eshop-contentnew .contentscroll .contentscrollbox {
  padding-bottom: 3.6rem;
  background: #ffffff;
  min-height: 100%;
}
.eshop-contentnew .eshop-banneronew {
  width: 100%;
  height: 5.56rem;
  position: relative;
  background: #f9f9f9;
  border-bottom: 0.01811594rem solid #dbdbdb;
}
.eshop-contentnew .eshop-banneronew img {
  width: 100%;
  height: 100%;
}
.eshop-contentnew .eshopcontent-btnlist {
  text-align: center;
  padding-top: 1.75120773rem;
  background: #ffffff;
}
.eshop-contentnew .eshopcontent-btnlist span.gray {
  background-color: #ccc;
}
.eshop-contentnew .eshopcontent-btnlist span {
  color: #ffffff;
  background: #f59a23;
  padding: 0.36231884rem 0rem;
  font-size: 0.39rem;
  line-height: 1;
  border-radius: 1.20772947rem;
  width: 3.62rem;
  display: inline-block;
}
.eshop-contentnew .eshopcontent-btnlist span:first-child {
  margin-right: 0.48rem;
}
.eshop-contentnew .eshopcontent-box {
  margin-top: 0.17rem;
  border-top: 0.01811594rem solid #dbdbdb;
  background: #ffffff;
}
.eshop-contentnew .eshopcontent-box .eshopcontent-box-title {
  color: #4a9c9d;
  font-size: 0.34rem;
  line-height: 1;
  padding: 0 0.38647343rem;
  padding-top: 0.4589372rem;
}
.eshop-contentnew .eshopcontent-box .eshopcontent-box-text {
  color: #333333;
  line-height: 0.6rem;
  font-size: 0.34rem;
  padding: 0 0.38647343rem;
  padding-top: 0.31400966rem;
}
.eshop-contentnew .eshopcontent-top {
  background: #ffffff;
  width: 100%;
  padding: 0.60386473rem 0.38647343rem;
  padding-top: 0.51rem;
  border-bottom: 0.01811594rem solid #dbdbdb;
}
.eshop-contentnew .eshopcontent-top .eshopcontent-title {
  padding-right: 1.81rem;
  font-size: 0.41rem;
  font-weight: bold;
  color: #181818;
  line-height: 0.58rem;
  position: relative;
}
.eshop-contentnew .eshopcontent-top .eshopcontent-title span {
  color: #ffffff;
  background: #f59a23;
  position: absolute;
  top: 0rem;
  right: 0rem;
  padding: 0.09661836rem 0.2173913rem;
  font-size: 0.27rem;
  font-weight: inherit;
  line-height: 1;
  border-radius: 1.20772947rem;
}
.eshop-contentnew .eshopcontent-top .eshopcontent-iconslist {
  overflow: hidden;
}
.eshop-contentnew .eshopcontent-top .eshopcontent-iconslist span {
  min-height: 0.39rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  float: left;
  padding: 0 0.16908213rem;
  color: #929292;
  font-size: 0.24rem;
  margin-right: 0.24rem;
  margin-top: 0.24rem;
}
.eshop-contentnew .eshopcontent-top .eshopcontent-iconslist span::after {
  content: '';
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  border-radius: 0.97rem;
}
.eshop-contentnew .eshopcontent-top .eshopcontent-iconslist .tip-name {
  padding-left: 0.58rem;
  background: url('../../../static/images/peopleIcon.png') no-repeat left 0 center / 0.3864rem auto;
}
.eshop-contentnew .eshopcontent-top .eshopcontent-iconslist .tip-time {
  padding-left: 0.58rem;
  background: url('../../../static/images/dateIcon.png') no-repeat left 0 center / 0.3864rem auto;
}
.eshop-contentnew .eshopcontent-top .eshopcontent-iconslist .tip-nums {
  padding-left: 0.58rem;
  background: url('../../../static/images/playnumsicon.png') no-repeat left 0 center / 0.3864rem auto;
}
