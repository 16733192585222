.scorelayer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 222224;
  width: 100%;
}
.scorelayer-title-layer::after {
  position: absolute;
  content: ' ';
  width: 0.39rem;
  height: 0.39rem;
  background: url(../../static/images/mall/video_layer_hover.png) no-repeat top left / 100% 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0.24rem;
}
.scorelayer-title-layer {
  font-size: 0.22rem;
  color: #ffffff;
  line-height: 0.36rem;
  padding-top: 0.07rem;
  padding-bottom: 0.1rem;
  padding-left: 0.82rem;
  padding-right: 0.17rem;
  background: #009999;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  top: 0.24rem;
  border-radius: 0.48309179rem 0 0 0.48309179rem;
}
