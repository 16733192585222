.common-search.op0 {
  opacity: 0;
}
.common-search {
  width: 100%;
  height: 1.45rem;
  padding: 0.28985507rem 0.36231884rem;
  background: #f9f9f9;
}
.common-search .search-input.isnoai {
  padding-right: 0.85rem;
}
.common-search .search-input {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 0.01449275rem solid #e1e1e1;
  border-radius: 1.45rem;
  position: relative;
  padding-left: 0.36rem;
  padding-right: 0.85rem;
  padding-right: 1.69rem;
  padding-right: 2.42rem;
  -webkit-box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}
.common-search .search-btn.isnoai {
  right: 0.29rem;
}
.common-search .search-btn {
  display: block;
  width: 0.6rem;
  height: 100%;
  position: absolute;
  z-index: 9;
  top: 0rem;
  bottom: 0rem;
  right: 0.29rem;
  right: 1.01rem;
  right: 2.1rem;
  background: url('../../static/images/commonSearch.png') no-repeat center center / 0.36rem auto;
}
.common-search .ai-search-text {
  display: block;
  width: 1.81rem;
  width: auto;
  height: 100%;
  height: 0.31400966rem;
  line-height: 1;
  color: #ec6602;
  position: absolute;
  z-index: 9;
  font-size: 0.31rem;
  top: 0rem;
  bottom: 0rem;
  margin: auto;
  right: 0.24rem;
}
.common-search .ai-search-btn::before {
  content: ' ';
  width: 0.02rem;
  height: 0.45rem;
  position: absolute;
  left: -0.22rem;
  top: 0rem;
  bottom: 0;
  margin: auto;
  background: #009999;
}
.common-search .ai-search-btn {
  display: block;
  width: 0.43rem;
  height: 100%;
  position: absolute;
  z-index: 9;
  top: 0rem;
  bottom: 0rem;
  right: 0.24rem;
  right: 1.33rem;
  background: url('../../static/images/ai/ai_seach_icon.png') no-repeat center center / 100% auto;
}
.common-search .search-value {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  color: #333333;
  font-size: 0.36rem;
}
