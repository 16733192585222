.exam_layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  display: block;
}
.exam_layer .result-number {
  color: #ffffff;
  width: 100%;
  height: 6.04rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.exam_layer .result-number h5,
.exam_layer .result-number p {
  color: #ffffff;
}
.iscroll-paddingless {
  overflow: hidden;
}
.exam-scroll.group {
  padding-top: 0;
}
.exam-scroll.group .exam_layer {
  position: fixed;
  top: -1.46rem;
}
.exam-scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #f9f9f9;
}
.exam-scroll .down-footer {
  bottom: 0;
}
.exam-scroll .exam-submit {
  width: 9.06rem;
  height: 0.8rem;
  background: #fe5b00;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.36rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 0.8rem;
}
.exam-scroll .exam-submit.active {
  opacity: 0.8;
}
.exam-wrap {
  padding: 0.48rem;
}
.exam-wrap .exam-title {
  font-size: 0.36rem;
  line-height: 0.51rem;
  color: #333333;
  margin-bottom: 0.36rem;
}
.exam-wrap .exam-li {
  min-height: 1.09rem;
  padding-top: 0.12rem;
  padding-bottom: 0.12rem;
  padding-left: 0.36rem;
  padding-right: 0.97rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.36rem;
  color: #333333;
  border-bottom: 0.01811594rem solid #dedede;
  position: relative;
}
.exam-wrap .exam-li:last-child {
  border: none;
}
.exam-wrap .exam-list {
  border: 0.01811594rem solid #dedede;
  background: #fff;
}
.exam-wrap .radio {
  display: block;
  width: 0.43rem;
  height: 0.43rem;
  border: 0.01811594rem solid #dedede;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  right: 0.36rem;
  top: 0;
  bottom: 0;
  margin: auto;
}
.exam-wrap .radio span {
  display: block;
  width: 0.19rem;
  height: 0.19rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  background: #fff;
  display: none;
}
.exam-wrap .radio.active {
  border-color: #ec6602;
  background: #ec6602;
}
.exam-wrap .radio.active span {
  display: block;
}
.exam-wrap .checkout {
  display: block;
  width: 0.43rem;
  height: 0.43rem;
  border: 0.01811594rem solid #dedede;
  background: #fff;
  position: absolute;
  right: 0.36rem;
  top: 0;
  bottom: 0;
  margin: auto;
}
.exam-wrap .checkout.active {
  border: none;
  background: url('../../static/images/labelactive.png') no-repeat center center / 100% 100%;
}
.resultBottom {
  width: 100%;
  height: 1.21rem;
  background: #fff;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  -webkit-box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.05);
}
.resultBottom .resultBtn {
  width: 9.06rem;
  height: 0.82rem;
  background: #fe5b00;
  border-radius: 0.82rem;
  color: #fff;
  font-size: 0.36rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  bottom: 0rem;
  right: 0rem;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}
.resultBottom .resultBtn.active {
  opacity: 0.8;
}
.resultBottom::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.02rem;
  background: #e3e3e3;
  position: absolute;
  top: 0rem;
  left: 0rem;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
}
.result-number {
  padding-top: 0.72rem;
  padding-bottom: 0.02rem;
  text-align: center;
}
.result-number h5 {
  font-size: 0.48rem;
  color: #333333;
}
.result-number h5 span {
  font-size: 0.8rem;
}
.result-number p {
  font-size: 0.36rem;
  color: #333333;
  padding-top: 0.12rem;
}
.analysis {
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.56rem;
  padding-top: 0.31rem;
  display: none;
}
.analysis h6 {
  color: #ec6602;
  font-size: 0.36rem;
  margin-bottom: 0.24rem;
}
.analysis.active {
  display: block;
}
.no-padding {
  padding-bottom: 0;
}
.graybackground {
  background: #f9f9f9;
}
.classTrue .radio {
  border: none;
  background: url('../../static/images/classTruepng.png') no-repeat center center / 100% 100%;
}
.classTrue .checkout {
  border: none;
  background: url('../../static/images/chenkoutTrue.png') no-repeat center center / 100% 100%;
}
.classLess .radio {
  border: none;
  background: url('../../static/images/classTruepng.png') no-repeat center center / 100% 100%;
}
.classLess .checkout {
  border: none;
  background: url('../../static/images/checkoutLess.png') no-repeat center center / 100% 100%;
}
.classError .radio {
  border: none;
  background: url('../../static/images/classErrorpng.png') no-repeat center center / 100% 100%;
}
.classError .checkout {
  border: none;
  background: url('../../static/images/checkoutError.png') no-repeat center center / 100% 100%;
}
